/* Page content */

.Directory-page {
  overflow-x: hidden;
}

.Page-content {
  margin-left: -10px;
  margin-right: -10px;
}

.Page-Header-content-full-width {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Tools-page .Page-Header-content-full-width {
  flex-wrap: nowrap;
} 

.Page-Header.Business-Directory h1 {
  justify-content: center;
}

.business-list-items {
  display: flex;
  flex: 70% 0 0;
  width: 70%;
  flex-wrap: wrap;
  align-content: flex-start;
}

.business-detail {
  display: flex;
  flex: 70% 0 0;
  width: 70%;
  align-content: flex-start;
  flex-wrap: wrap;
}

.business-detail-inner {
  flex: 100% 0 0;
  display: flex;
  border-radius: 5px;
  border: 2px solid #CCC;
  background: #FFF;
  padding: 20px 40px;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-content: flex-start;
}

.business-detail-inner .business-location {
  display: flex;
  flex: 100% 0 0;
  flex-wrap: wrap;
}

.business-detail-inner .business-location iframe {
  width: 100%;
  height: 350px;
}

.business-detail-inner h2 {
  margin-bottom: 15px;
  font-family: "Outfit";
  font-weight: 700;
  font-size: 25px;
}

.business-detail-inner .business-description {
  display: flex;
  flex: 100% 0 0;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(0,0,0,0.05)
}

.business-detail-inner .business-location h2,
.business-detail-inner .business-description h2 {
  font-size: 30px;
}

.business-detail-inner .business-description p {
  text-align: justify;
  font-family: "Outfit";
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 25px;
  flex: 100%;
  width: 100%;
}

.Business-No-Website-URL-Found {
  font-family: "Outfit";
  font-size: 18px;
  display: inherit;
}

.business-list-sidebar {
  flex: 30% 0 0;
  width: 30%;
  padding-left: 5px;
  box-sizing: border-box;
}

.business-list-sidebar h2 {
  text-align: left;
  font-family: "Outfit";
}

.business-list-categories-inner ul {
  min-height: 200px;
  max-height: 200px;
  height: 200px;
  overflow: hidden;
  overflow-y: hidden;
  overflow-y: scroll; 
}

.business-category-clear-button {
  background: #ff8484;
  padding-left: 15px;
  margin-right: 20px;
  border-radius: 10px;
  padding-top: 4px;
  border: 2px solid rgba(0,0,0,0.1);
}

.business-list-categories h2, 
.business-list-locations h2,
.business-list-social-media-links h2 {
  display: flex;
  background: #000;
  margin: 0;
  color: #FFF;
  padding: 10px 30px;
  font-family: "Outfit";
  font-weight: 700;
  line-height: 1em;
  border-radius: 10px 10px 0 0;
}

.business-list-categories-inner, 
.business-list-locations-inner,
.business-list-social-media-links-inner {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 30px;
  padding-bottom: 0;
  background: #FFF;
  border: 2px solid #CCC;
  border-top: 0;
  border-radius: 0 0 10px 10px;
}

.business-list-social-media-links-inner { 
  padding-bottom: 20px;
}

.Business-Directory-Detail-Page .no-social-media-profiles {
  font-family: "Outfit";
  font-size: 16px;
  line-height: 1em;
}

.business-list-social-media-links-inner .social-media-item {
  display: flex;
  flex: 100% 0 0;
  width: 100%;
  align-items: center;
  font-size: 16px;
  text-align: left;
  font-family: "Outfit";
  border-bottom: 1px solid #EEE;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.business-list-social-media-links-inner .social-media-item a {
  color: #000;
  text-decoration: unset;
  width: 100%;
}

.business-list-social-media-links-inner .social-media-item:last-child {
  border-bottom: 0;
}

.business-list-social-media-links-inner .social-media-item i {
  font-size: 18px;
  margin-right: 5px;
}

.business-list-locations {
  margin-top: 10px;
}

.business-list-categories ul, .business-list-locations ul {
  font-family: "Outfit";
  font-size: 16px;
  list-style: none;
  padding: 0;
  margin-top: 0;
  text-align: left;
  width: 100%;
}

.business-list-categories ul li {
  margin-bottom: 4px;
  padding-bottom: 4px;
  border-bottom: 1px solid #EEE;
}

.business-list-categories ul li a, .business-list-locations ul li a {
  text-decoration: unset;
  color: #000;
  cursor: pointer;
}

.business-list-categories ul li a:hover, .business-list-locations ul li a:hover {
  opacity: 0.6;
}

.business-list-locations ul li ul {
  padding-left: 15px;
}

.business-list-locations ul li {
  margin-bottom: 4px;
  padding-bottom: 4px;
  border-bottom: 1px solid #EEE;
}

.business-list-locations ul li:last-child {
  border-bottom: 0;
}


.business-list-locations ul li ul {
  padding-top: 4px;
}


/* Business Directory */

.Business-Directory .directory-business-search-container {
  display: flex;
  flex: 100% 0 0;
  flex-wrap: nowrap;
  justify-content: center;
}

.Business-Directory .directory-business-search-container input {
  display: flex;
  flex: 65% 0;
  border-radius: 15px 0 0 15px;
  font-size: 20px;
  padding: 10px 25px;
  font-family: "Outfit";
}

.Business-Directory .directory-business-search-container button {
  display: flex;
  flex: 15% 0;
  background: #a2c860;
  color: #FFF;
  font-family: "Outfit";
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 0;
  font-size: 20px;
  border-radius: 0 15px 15px 0;
  cursor: pointer;
}

.Business-Directory .directory-business-search-container button:hover {
  opacity: 0.8;
}

.business-list-items ul.pagination {
  display: flex;
  flex: 100% 0 0;
  margin: 0;
  padding: 0 5px;
}

.business-list-items ul.pagination li {
  display: flex;
  background: #000;
  padding: 10px;
  font-size: 17px;
  font-family: "Outfit";
  color: #FFF;
  line-height: 1em;
  width: 20px;
  height: 18px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  gap: 5px;
  display: inline-flex;
  margin: 0 3px;
  line-height: 1em;
}

.business-list-items ul.pagination li:hover {
  opacity: 0.8;
}

.business-list-items ul.pagination li.active {
  cursor: not-allowed;
  opacity: 0.7;
  pointer-events: none; /* Disable pointer events */
}

/* Business directory - Highlights */

.Page-Header-content-30 {
  background: rgba(0,0,0,0.2);
  padding: 50px 30px;
  box-sizing: border-box;
  border: 2px solid rgba(0,0,0,0.05)
}

.Page-Header-content-70 {
  align-content: flex-start;
  position: relative;
}

.Business-Header-Highlights {
  width: 100%;
}

.Business-Header-Highlights .Business-Reviews {
  width: 100%;
  border-bottom: 2px solid rgba(0,0,0,0.05);
  margin-bottom: 10px;
  padding-bottom: 10px;
  position: relative;
} 

.Business-Header-Highlights .Business-Reviews .Average-Rating-Label {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 35px;
  font-family: "Outfit";
  line-height: 1em;
}

.Business-Header-Highlights .Business-Reviews .Business-Reviews-Stars {
  display: flex;
  flex: 100% 0 0;
}

.Business-Header-Highlights .Business-Reviews-Count {
  font-family: "Outfit";
  font-size: 20px;
}

.Business-Header-Highlights .Business-Reviews-Count .Add-A-Review {
  font-size: 16px;
  line-height: 1em;
  color: #AAA;
  cursor: pointer;
}

.Business-Header-Highlights .Business-Reviews-Count .Add-A-Review i {
  font-size: 14px;
  color: #AAA;
}

.Business-Header-Highlights .Business-Reviews i {
  color: gold;
  font-size: 30px;
  margin: 0 2px;
}

.Business-Header-Highlights .Business-Reviews i.dark-gray {
  color: #a4a4a4;
}

.Business-Hours-Of-Operation {
 width: 100%;
 font-size: 16px;
 font-weight: 500;
 font-family: "Outfit";
 margin-bottom: 10px;
 display: flex;
 line-height: 1em;
}

.operation-item {
  display: flex;
  flex: 100% 0;
  border-bottom: 1px solid #eee;
  padding-bottom: 4px;
  margin-bottom: 4px;
}

.operation-item p {
  margin: 0;
  padding: 0;
}

.operation-item .operation-day {
  display: flex;
  flex: 30% 0;
  flex-wrap: wrap;
  font-family: "Outfit";
  color: #333;
  font-size: 16px;
  font-weight: 500;
}

.operation-item .operation-open {
  display: flex;
  flex: 35% 0 0;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.operation-item .operation-open strong, .operation-item .operation-closed strong {
  display: flex;
  flex: 100% 0;
  width: 100%;
  font-size: 16px;
  font-family: "Outfit";
}

.operation-item .operation-closed {
  display: flex;
  flex: 35% 0 0;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.Business-Hours-Of-Operation:hover {
  opacity: 0.8;
  cursor: pointer;
}

.Business-Hours-Of-Operation i {
  margin-right: 5px;
}

.Category-Name {
  margin-left: 10px;
  text-transform: capitalize;
}

.Verified-Badge {
  display: inherit;
  background: cyan;
  color: #000;
  padding: 5px 20px;
  align-items: center;
  border-radius: 20px;
  border: 3px solid rgba(0,0,0,0.1);
  font-family: "Outfit";
  position: absolute;
  right: 30px;
  font-size: 19px;
  font-weight: 300;
}

.Verified-Badge i {
  margin-right: 5px;
}

.Contact-Details {
  display: flex;
  flex: 100% 0 0;
  flex-wrap: wrap;
  font-size: 20px;
  font-family: "Outfit";
}

.Contact-Details i {
  margin-right: 12px;
  font-size: 30px;
}

.Contact-Details span {
  align-items: center;
  display: flex;
}

.Contact-Details span:first-child {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(0,0,0,0.2);
}

.Action-Buttons {
  display: flex;
  margin-top: 20px;
  font-family: "Outfit";
  font-size: 20px;
}

.Action-Buttons a:first-child {
  margin-right: 15px;
}

.Action-Buttons a:nth-child(2) {
  background: #a2c860;
}

.Action-Buttons a {
  background: yellow;
  color: #000;
  padding: 5px 20px;
  border-radius: 20px;
  font-size: 19px;
  border: 3px solid rgba(0,0,0,0.1);
  transition: 1s opacity;
}

.Action-Buttons a:hover {
  opacity: 0.7;
  cursor: pointer;
}

a.Business-View-Website {
  color: #000;
  font-size: 20px;
  line-height: 1em;
  text-decoration: unset;
  font-family: "Outfit";
  background: #e4ffb4;
  border: 0;
  padding: 6px 30px;
  display: flex;
  align-items: center;
}

a.Business-View-Website i {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

.Business-Open-Status {
  color: lightgreen;
  font-family: "Outfit";
  font-size: 20px;
  display: flex;
  margin-bottom: 5px;
}

.image-container {}

.business-images-container {
  overflow: hidden;
  display: flex;
  flex: 80% 0 0;
}

.business-images {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 10px;
  transition: transform 0.5s ease; /* Add transition for smooth transformation */
}

.carousel-image {
  width: 33.33%; /* Display only 3 images at a time */
  height: 120px; /* Adjust the height as needed */
  object-fit: cover;
  border: 1px solid #ddd;
  background: #EEE;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 10px;
  opacity: 1; /* Set initial opacity to 1 */
  transition: transform 0.5s ease; /* Add transition for smooth transformation */
}

.business-images .fa-arrow-right {
  font-size: 24px;
  margin-top: 5px;
}

.business-images::-webkit-scrollbar {
  display: none;
}

.arrow-container {
  display: flex;
  flex-wrap: wrap;
  margin-right: 10px;
  align-content: flex-start;
}

.arrow-container .arrow {
  height: 58px;
  align-items: center;
  display: flex;
  padding: 0 10px;
  background: #FFF;
  cursor: pointer;
  justify-content: center;
  width: 100%;
}

.arrow-container .arrow-inner-container:last-child {
  display: flex;
  align-items: flex-end;
}

.arrow-container .arrow.disabled {
  cursor: not-allowed;
}

.arrow-container .arrow.disabled i {
  opacity: 0.5;
}

.arrow-container .arrow.disabled:hover {
  background: #FFF;
}

.arrow-container .arrow:hover {
  background: #EEE;
}

.arrow-container .arrow i {
  font-size: 20px;
}



.carousel-container {
  overflow: hidden;
  position: relative;
  display: flex;
  flex: 100% 0 0;
}

.business-images {
  display: flex;
  transition: transform 0.5s ease;
}

.image-container {
  width: 25%; /* Display only 4 images at a time */
  display: flex;
  flex: 25% 0 0;
  overflow: hidden;
  flex-shrink: 0;
}

.business-images.left-transition {
  transform: translateX(calc(-100% - 10px));
}

.business-images.right-transition {
  transform: translateX(calc(100% + 10px));
}


.carousel-image {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border: 1px solid #ddd;
  background: #eee;
  border-radius: 5px;
  margin-right: 10px;
  opacity: 1;
  transition: opacity 0.5s ease;
}

.carousel-container .submit-your-own {
  flex: 10% 0;
  flex-shrink: 0;
  height: 120px; /* Adjust the height as needed */
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 0;
  cursor: pointer;
}

.carousel-container .submit-your-own:hover {
  background-color: #e0e0e0;
}

.carousel-container .submit-your-own.image {
  background-color: #fff;
  cursor: pointer;
  padding: 0 20px;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 1em;
  font-family: "Outfit";
  border: 1px solid #ccc;
  height: 125px;
  border-radius: 0;
}

.carousel-container .submit-your-own.image:hover {
  background: #FAFAFA;
}

.carousel-container .submit-your-own.image .text {
  position: relative;
  z-index: 9;
  font-size: 18px;
  color: #000;
  line-height: 1em;
}

.carousel-container .submit-your-own.image i {
  color: #aaa;
  font-size: 30px;
  z-index: 0;
  margin-top: 5px;
}

/* Star */

.star-rating {
  display: inline-block;
  font-size: 24px; /* Adjust as needed */
}

.star i {
  color: #ddd; /* Light grey color for non-hovered stars */
  cursor: pointer;
  transition: color 0.2s;
}

.fa-star:before {
  cursor: pointer;
}

.star-filled {
  color: gold;
}

.filled:before {
  color: gold; /* Gold color for hovered stars */
  transition: color 0.2s;
  cursor: pointer;

}

.star.filled {
  color: gold; /* Gold color for filled stars */
}

/* City list directory */

/* Page header for city list */

.Directory-City-Page h1 {
  text-transform: capitalize;
}

.Directory-City-Page .region-meta {
  text-transform: capitalize;
}

.Business-Directory-Detail-Page .Page-Header-content-70 h1,
.Business-Directory-Detail-Page .Page-Header-content-70 h2 {
  justify-content: flex-start;
  text-align: left;
}


/* Media queries */

@media screen and (max-width: 1210px){
  .Business-Directory-Detail-Page .Page-content {
    padding: 0 2rem;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .Business-Directory-Detail-Page .Business-Header-Highlights .Business-Reviews .Average-Rating-Label {
    font-size: 23px;
  }
  .Business-Directory-Detail-Page section.Page-Header.Business-Directory,
  .Directory-page .Page-content {
    padding: 0 28px;
  }
  .Tool-directory-page .Page-content {
    padding: 0 32px;
  }
}

@media screen and (max-width: 1150px){
  .Business-Directory-Detail-Page .Business-Header-Highlights .Business-Reviews i {
    font-size: 24px;
  }
}

@media screen and (max-width: 980px){
  .Business-Directory-Detail-Page a.Business-View-Website {
    font-size: 16px;
  }
  .Business-Directory-Detail-Page .Business-Header-Highlights .Business-Reviews i {
    font-size: 21px;
    margin: 0 1px;
  }
  .Business-Directory-Detail-Page .Business-Header-Highlights .Business-Reviews-Count .Add-A-Review {
    font-size: 15px;
  }
  .Business-Directory-Detail-Page .Business-Header-Highlights .Business-Reviews .Average-Rating-Label {
    font-size: 20px;
  }
  .Business-Directory-Detail-Page .Business-Header-Highlights .Business-Reviews-Count {
    font-size: 18px;
  }
  .Business-Directory-Detail-Page .Business-No-Website-URL-Found {
    font-size: 16px;
  }
  .Business-Directory-Detail-Page .Page-Header h1 {
    font-size: 2.1rem;
  }
  .Business-Directory-Detail-Page .Contact-Details i {
    font-size: 21px;
  }
}

@media screen and (max-width: 880px){
  .Business-Directory .Page-content {
    width: 100%;
  }
  .Business-Directory-Detail-Page .Page-Header-content-30 {
    padding: 35px 20px;
  }
  .Business-Directory-Detail-Page .Page-Header-content-70 {
    padding-right: 5%;
    box-sizing: border-box;
  }
  .Business-Directory-Detail-Page .Action-Buttons a {
    font-size: 16px;
  }
  .Business-Directory-Detail-Page .business-list-social-media-links h2 {
    font-size: 18px;
  }
  .Business-Directory-Detail-Page .business-list-social-media-links-inner {
    font-size: 16px;
    padding: 10px;
  }
  .Business-Directory-Detail-Page .business-detail-inner .business-location h2, 
  .Business-Directory-Detail-Page .business-detail-inner .business-description h2 {
    font-size: 20px;
  }
  .Business-Directory-Detail-Page .business-detail-inner .business-description p {
    font-size: 16px;
  }
  .Business-Directory-Detail-Page .business-detail-inner .business-location iframe {
    height: 275px;
  }
  .Business-Directory-Detail-Page .Business-Header-Highlights .Business-Reviews-Count .Add-A-Review {
    display: flex;
  }
  .Business-Directory-Detail-Page a.Business-View-Website {
    padding: 6px 15px;
  }
  .Business-Directory-Detail-Page .Business-Header-Highlights .Business-Reviews .Average-Rating-Label {
    font-size: 17px;
  }
  .Business-Directory-Detail-Page .Business-Header-Highlights .Business-Reviews i {
    margin: 0;
  }
  .Business-Directory-Detail-Page .Business-No-Website-URL-Found {
    font-size: 14px;
  }
  .Business-Directory-Detail-Page .business-list-social-media-links h2 {
    padding: 10px 15px;
    font-size: 17px;
  }
}

@media screen and (max-width: 750px){
  .Business-Directory-Detail-Page .Contact-Details span {
    font-size: 18px;
    margin: 0;
  }
  .Business-Directory-Detail-Page .Business-Header-Highlights .Business-Reviews i {
    font-size: 17px;
  }
  .Business-Directory-Detail-Page .Business-Header-Highlights .Business-Reviews-Count {
    font-size: 17px;
  }
  .Business-Directory-Detail-Page .Business-Header-Highlights .Business-Reviews .Average-Rating-Label {
    font-size: 14px;
  }
  .Business-Directory-Detail-Page a.Business-View-Website i {
    display: none;
  }
  .Business-Directory-Detail-Page a.Business-View-Website {
    padding: 6px 8px;
    text-align: center;
    justify-content: center;
  }
  .Directory-page .Page-content {
    width: 100%;
  }
  .Directory-page .business-list-sidebar {
    display: none;
  }
  .Business-Directory-Detail-Page .Page-Header-content-70 {
    position: unset;
  }
  .Business-Directory-Detail-Page .Page-Header {
    position: relative;
  }
  .Business-Directory-Detail-Page .Verified-Badge {
    top: 20px;
    right: 30px;
    font-size: 16px;
  }
  /* Arrows */
  .Business-Directory-Detail-Page .arrow-container .arrow {
    height: 42px;
  }
  .Business-Directory-Detail-Page .carousel-image {
    height: 90px;
  }
  .Business-Directory-Detail-Page .carousel-container .submit-your-own.image {
    height: 80px;
  }
  .Business-Directory-Detail-Page .carousel-container .submit-your-own.image .text {
    font-size: 16px;
  }
  .Business-Directory-Detail-Page .image-container {
    flex: 33.3% 0 0;
    flex-shrink: 0;
    width: 33.3%;
  }
  .Business-Directory-Detail-Page .arrow-container .arrow:first-child {
    margin-bottom: 5px;
  }
  .Business-Directory-Detail-Page .arrow-container .arrow {
    height: 38px;
  }
  .Business-Directory-Detail-Page .carousel-image {
    height: 80px;
  }
  .Business-Directory-Detail-Page .carousel-container .submit-your-own.image {
    height: 82px;
  }
  .Business-Directory-Detail-Page .no-social-media-profiles {
    font-size: 15px;
  }
  .Business-Directory-Detail-Page .no-social-media-profiles {
    padding: 15px 10px;
    font-size: 15px;
  }
} 

@media screen and (max-width: 650px){
  .Business-Directory-Detail-Page .Contact-Details span {
    width: 100%;
    border: 0;
    font-size: 16px;
  }
  .Business-Directory-Detail-Page .Contact-Details span:first-child {
    margin-top: 0;
  }
  .Business-Directory-Detail-Page .Contact-Details span {
    margin-top: 3px;
  }
  .Business-Directory-Detail-Page .Contact-Details i {
    font-size: 16px;
  }
  .Business-Header-Highlights .Business-Reviews {
    position: unset;
  }
  .Business-Directory-Detail-Page .Page-Header-content-30 {
    position: relative;
  }
  .Business-Directory-Detail-Page .Business-Header-Highlights .Business-Reviews .Average-Rating-Label {
    top: 15px;
    left: 20px;
    right: unset;
  }
  .Business-Directory-Detail-Page a.Business-View-Website {
    position: relative;
  }
  .Business-Directory-Detail-Page a.Business-View-Website {
    font-size: 15px;
  }
  .Business-Directory-Detail-Page a.Business-View-Website i {
    position: absolute;
    right: 4px;
  }
  .Business-Directory-Detail-Page .Business-Hours-Of-Operation {
    font-size: 14px;
  }
  .Business-Directory-Detail-Page .Verified-Badge {
    right: 0;
    border-radius: 50px 0 0 50px;
  }
  .Business-Directory-Detail-Page .business-list-social-media-links h2 {
    font-size: 16px;
  }
  .Business-Directory-Detail-Page .business-list-social-media-links .social-media-item span {
    display: none;
  }

  .Business-Directory-Detail-Page .business-list-social-media-links .social-media-item span .social-media-item {
    width: 50%;
  }

   .Business-Directory-Detail-Page .business-list-social-media-links .social-media-item i {
    font-size: 40px;
   }
}

@media screen and (max-width: 570px){
  .Business-Directory-Detail-Page .Page-Header-content-30 {
    padding: 35px 15px;
  }
  .Business-Directory-Detail-Page .Business-Header-Highlights .Business-Reviews .Average-Rating-Label {
    left: 15px;
  }
  .Business-Directory-Detail-Page a.Business-View-Website {
    text-align: center;
    justify-content: center;
    font-size: 15px;
  }
  .Business-Directory-Detail-Page a.Business-View-Website i {
    display: none;
  }
  .Business-Directory-Detail-Page a.Business-View-Website {
    font-size: 14px;
  }
  /* Arrows container */
  .Business-Directory-Detail-Page .image-container {
    flex: 50% 0 0;
    flex-shrink: 0;
    width: 50%;
  }
  .Business-Directory-Detail-Page .carousel-image {
    height: 80px;
  }
  .Business-Directory-Detail-Page .arrow-container .arrow {
    height: 35px;
  }
  .Business-Directory-Detail-Page .arrow-container .arrow {
    height: 38px;
  }
  .business-list-social-media-links-inner .social-media-item {
    width: 50%;
    flex: 50%;
  }
  .Business-Directory-Detail-Page .business-list-social-media-links-inner {
    border: 0;
  }
  .Business-Directory-Detail-Page .Page-content,
  .Directory-page .Page-content {
    padding: 0 10px;
  }
  .Business-Directory-Detail-Page .no-social-media-profiles {
    padding: 10px;
    font-size: 13px;
  }
  .Business-Directory-Detail-Page .business-detail-inner,
  .Business-Directory-Detail-Page .business-list-social-media-links-inner {
    padding: 10px 15px;
  }
  .Business-Directory-Detail-Page .business-detail-inner .business-description p {
    font-size: 15px;
  }
}

@media screen and (max-width: 500px){
  .Business-Directory-Detail-Page .Page-Header-content-30 {
    position: absolute;
    right: 0;
  }
  .Business-Directory-Detail-Page .Page-Header-content-70 {
    flex: 100%;
    max-width: 100%;
    padding-right: 30%;
  }
  .Business-Directory-Detail-Page .Page-Header h1 {
    font-size: 1.7rem;
  }
  .Business-Directory-Detail-Page .Page-Header h2 {
    font-size: 1.05rem;
  }
  .Business-Directory-Detail-Page .Business-Header-Highlights .Business-Reviews-Count .Add-A-Review {
    font-size: 14px;
  }
  .Business-Directory-Detail-Page .Business-No-Website-URL-Found {
    font-size: 13px;
  }
  .Business-Directory-Detail-Page .Business-Hours-Of-Operation {
    flex-wrap: wrap;
  }
  .Business-Directory-Detail-Page .Business-Hours-Of-Operation i {
    margin-bottom: 3px;
  }
  .Business-Directory-Detail-Page .business-list-social-media-links .social-media-item a {
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
    color: #000;
   }
   .Business-Directory-Detail-Page .business-list-social-media-links .social-media-item i {
    color: #000;
   }
   .Business-Directory-Detail-Page .business-list-social-media-links-inner {
    padding: 0;
   }
   .Business-Directory-Detail-Page .business-list-social-media-links .social-media-item a {
    font-size: 14px;
   }
   .Page.Business-Directory-Detail {
    padding: 5px 10px;
    margin-top: -15px;
   }
   .Page.Business-Directory-Detail .Page-content {
    margin: 0;
    padding: 0;
   }
   .Business-Directory-Detail-Page .Business-Header-Highlights .Business-Reviews-Count {
    font-size: 15px;
   }
   .Business-Directory-Detail-Page .business-list-social-media-links h2 {
    font-size: 13px;
   }
}

@media screen and (max-width: 400px){
  .Business-Directory-Detail-Page .Business-Header-Highlights .Business-Reviews i {
    font-size: 14px;
  }
  .Business-Directory-Detail-Page .Business-Header-Highlights .Business-Reviews-Count {
    font-size: 15px;
  }
  .Business-Directory-Detail-Page .Business-No-Website-URL-Found i {
    display: none;
  }
}

@media screen and (max-width: 380px){
  .Business-Directory-Detail-Page .Page-Header-content-30 {
    position: relative;
    right: 0;
    width: 100%;
    flex: 100%;
    max-width: 100%;
    margin-top: 10px;
  }
  .Business-Directory-Detail-Page .Page-Header-content-70 {
    padding-right: unset;
  }
  .Business-Directory-Detail-Page .business-list-sidebar {
    margin: 0;
    padding: 10px 0;
  }
  .business-detail {
    flex: 100%;
    width: 100%;
  }
  .business-list-sidebar {
    flex: 100%;
    width: 100%;
  }
  .Business-Directory-Detail-Page .business-list-social-media-links .social-media-item a {
    text-align: center;
    justify-content: center;
    width: 20%;
  }
  .Business-Directory-Detail-Page .business-detail-inner .business-location iframe {
    height: 275px;
  }
  .Page-Header-Main-Section-Full-Width {
    padding: 80px 0 35px;
  }
}