.Page-Header {
    background-size: cover !important;
    background-position: center center !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(87, 4, 134, 0.9);
  }

  .Page-Header-Main-Section {
    flex: 70% 0;
    display: flex;
    padding: 80px 0;
    justify-content: center;
    background-size: cover;
    align-items: center;
  }

  .Page-Header-Main-Section-Full-Width {
    flex: 100% 0;
    display: flex;
    padding: 120px 0;
    justify-content: center;
    background-size: cover;
    align-items: center;
  }

  .Tools .Page-Header-Main-Section-Full-Width {
    padding: 90px 0;
  }

  .Page-Header-Search-For-Business {
    flex: 30% 0;
    display: flex;
    position: relative;
    padding: 80px 0;
    min-height: 383px;
    background: #5a7d19;
    flex-wrap: wrap;
    flex-direction: column;
    box-shadow: -20px 20px 0px #5aaa43;
    border-radius: 0 0 0 150px;
  }

  .Page-Header-Search-For-Business i {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 70px;
    z-index: 0;
    color: rgba(0,0,0,0.1);
  }

  .Page-Header-Search-For-Business input, .Page-Header-Search-For-Business select, .Page-Header-Search-For-Business button {
    background: #FFF;
    font-family: "Outfit";
    font-size: 1.3em;
    padding: 10px 25px;
    width: 100%;
    border-radius: 20px;
    margin-bottom: 10px;
    border: 0;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
  }

  .Page-Header-Search-For-Business button {
    background-image: linear-gradient(rgba(87, 4, 134, 0.9), rgba(98, 0, 134, 0.88));
    color: #FFF;
    cursor: pointer;
  }

  .Page-Header .Page-Header-Search-For-Business h2 {
    color: #FFF;
    font-size: 2em;
    text-align: left;
  }

  .Page-Header-Search-Content {
    padding: 0 50px;
  }

  .Page-Header-Search-For-Business h2,
  .Page-Header-Search-For-Business input {
    display: flex;
    flex: 100% 0 0;
  }

  .Page-Header-content {
    max-width: 750px;
    text-align: left;
    color: #fff;
    justify-content: center;
  }

  .Page-Header-content-full-width {
    max-width: 1200px;
    text-align: left;
    color: #fff;
    justify-content: center;
    flex: 100% 0 0;
    position: relative;
    z-index: 1;
  }

  .Page-Header-content-70 {
    max-width: 70%;
    text-align: left;
    color: #fff;
    justify-content: left;
    flex: 70% 0 0;
    display: flex;
    flex-wrap: wrap;
    padding-right: 5%;
    box-sizing: border-box;
  }

  
/* Create a pseudo-element for the gradient overlay */
.Page-Header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(87, 4, 134, 0.1),
    rgba(98, 0, 134, 0.98)
  );
  pointer-events: none; /* ensures clicks pass through */
  z-index: 1;
  /* Start with normal brightness */
  filter: brightness(1);
  /* Animate the brightness */
  animation: pulseGradient 5s ease-in-out infinite;
}

/* Define the keyframes for the subtle brightness pulse */
@keyframes pulseGradient {
  0% {
    filter: brightness(1);
  }
  50% {
    filter: brightness(1.25);
  }
  100% {
    filter: brightness(1);
  }
}


  .Page-Header-content-30 {
    max-width: 30%;
    text-align: left;
    color: #fff;
    justify-content: center;
    flex: 30% 0 0;
    display: flex;
    border-radius: 10px;
  }

  .Page-Header-v1-left {
    max-width: 70%;
  }
  
  .Page-Header h1 {
    font-size: 3.3rem;
    line-height: 1em;
    margin-bottom: 0.3em;
    margin-top: 0;
    font-family: "Outfit";
    font-weight: 900;
    display: flex;
    flex: 100% 0 0;
    text-align: center;
    justify-content: center;
  }
  
  .Page-Header h2 {
    font-size: 1.6em;
    line-height: 1.1em;
    margin-bottom: 1em;
    margin-top: 0;
    font-family: "Outfit";
    font-weight: 500;
    width: 100%;
    text-align: center;
  }

  .Page-Header .divider {
    height: 3px;
    background: #a4ce59;
    width: 15%;
    margin: 1.2em 0;
    justify-content: center;
  }
  
  .Page-Header p {
    font-size: 1.35em;
    margin-bottom: 2rem;
    margin-top: 0;
    font-family: "Outfit";
    font-weight: 300;
  }
  
  .Page-Header-cta {
    display: inline-block;
    background-color: #b5003b;
    color: #fff;
    border: none;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .Page-Header-cta:hover {
    background-color: #dd4175;
  }

  .Page-Header.Tools .Page {
    justify-content: center;
  }

  .Page-Header.Tools .Page-Header-content-full-width {
    justify-content: center;
    flex-wrap: wrap;
  }

  .Page-Header.Tools .Page-Header-content-full-width h1,
  .Page-Header.Tools .Page-Header-content-full-width h2 {
    text-align: center;
    justify-content: content;
  }

  .Page-Header.Tools .Page-Header-content-full-width h1 {
    font-size: 85px;
    margin-bottom: 0;
  }

  .Page-Header.Tools .Page-Header-content-full-width h2 {
    font-size: 23px;
    padding-bottom: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    margin-left: 35px;
  }

  .Page-Header.Tools-Single a {
    background: #FFF;
    border-radius: 50px;
    color: #000;
    padding: 6px 15px;
    font-size: 20px;
    text-decoration: unset;
    font-weight: 500;
    font-family: "Outfit";
  }

  .Page-Header.Tools-Single .tool-cta-action-next-step {
    padding: 12px 70px;
    background: #61ba48;
  }

  .Page-Header.Tools-Single a {

  }

  .Page-Header.Tools-Single a i {
    padding-right: 5px;
  }

  .Page-Header.Tools-Single .Page-Header-content-70 h1,
  .Page-Header.Tools-Single .Page-Header-content-70 h2 {
    display: flex;
    flex-wrap: wrap;
    flex: 100% 0 0;
    text-align: left;
  }

  .Page-Header.Tools-Single .Page-Header-content-70 h2 {
    display: -webkit-box;         /* Use flexbox-like behavior for text */
    -webkit-line-clamp: 3;       /* Limits to 3 lines */
    -webkit-box-orient: vertical; /* Sets the box orientation to vertical */
    overflow: hidden;            /* Hides overflowing text */
    text-overflow: ellipsis;     /* Adds ellipses for truncated text */
    white-space: normal;         /* Allows text to wrap */
  }

  .Page-Header.Tools-Single .Page-Header-content-full-width {
    display: flex;
  }

  .Page-Header.Tools-Single h1 {
    padding-top: 20px;
    justify-content: flex-start;
  }

/* BUSINESS DIRECTORY */

.Page-Header.Business-Directory .Page-Header-content-100 {
  display: flex;
  flex: 100% 0 0;
  flex-wrap: wrap;
  justify-content: center;
}

.Page-Header.Business-Directory .Page-Header-content-100 h2 {
  text-align: center;
}
  
/* PAGE HEADER MEDIA QUERIES */

@media screen and (max-width: 1220px){
  section.Page-Header {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1150px){

}

@media screen and (max-width: 980px){
  .Page-Header-Main-Section-Full-Width {
    padding: 80px 0;
  }
  .Page-Header h1 {
    font-size: 2.8rem;
  }
  .Page-Header h2 {
    font-size: 1.4em;
  }
  .Page-Header.Tools-Single .Page-Header-content-70 h2 {
    font-size: 20px;
  }
}


@media screen and (max-width: 880px){

}

@media screen and (max-width: 750px){
  .Page-Header h1 {
    font-size: 2.2rem;
  }
  .Page-Header h2 {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 320px){

}
