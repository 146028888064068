

.features-component {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  margin-top: -25px;
  padding-bottom: 50px;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  flex: 25%;
  width: 25%;
  flex-wrap: wrap;
  align-content: flex-start;
}

.tabs button img {
  width: 100%;
  border-radius: 15px 15px 0 0;
}

.tabs button {
  width: 100%;
  border: 0;
    border-top-width: 0px;
    border-top-style: none;
    border-top-color: currentcolor;
  border-radius: 15px;
  padding: 0 0 10px;
  margin: 0 0 10px;
    margin-bottom: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  background: #ccc;
  border-top: 0;
  transition: background-color 0.3s;
  font-family: "Outfit";
  font-size: 18px;
  line-height: 1em;
  color: #000;
  position: relative;
  padding: 0;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.tabs button span {
background: #FFF;
  border-radius: 15px;
  padding: 7px 15px;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  border: 4px solid #ddd;
  margin-top: 10px;
  border-top: 0;
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
}

.tabs button:hover {
  background-color: #000;
}

.tabs button:hover span {
  background: #000;
  color: #FFF;
  border-color: #222;
  transition: 0.5s background, 0.5s color, 0.5s border-color;
}

/* Feature container */

.feature-overview {
  display: flex;
  flex: 100%;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
}

/* Example box */

.example-container {
  width: 100%;
}

.unique-message {
  background-color: #FFF;
  padding: 20px 35px;
  border: 5px solid #DDD;
  border-radius: 15px;
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 20px;
  font-family: "Outfit";
  text-align: left;
  display: flex;
}

.unique-message h2 {
  margin: 0;
}

.unique-message i {
  flex: 0 1;
  display: flex;
  font-size: 35px;
  padding-right: 20px;
  box-sizing: border-box;
}

.unique-message-content {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.unique-message p {
  flex: 100% 0 0;
  flex-wrap: wrap;
  display: flex;
}

.example-box {
  display: flex;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  font-size: 18px;
}

.unique-message p {
  padding: 0;
  margin: 0;
}

.example-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.example-image {
  object-fit: contain;
  display: flex;
  width: 45%;
  padding: 10px;
  border-radius: unset;
  box-sizing: border-box;
}

.example-description {
  padding: 20px 35px;
  width: 55%;
  text-align: left;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  box-sizing: border-box;
}

.example-description h3 {
  margin: 0 0 10px 0;
  font-size: 1.5em;
  line-height: 1em;
  font-family: "Outfit";
}

.example-description p {
  margin: 0 0 20px 0;
  font-family: "Outfit";
}

.example-description button {
  padding: 10px 20px;
  font-family: "Outfit";
  background-color: #000;
  font-size: 16px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.example-description button:hover {
  background-color: #0056b3;
}

/* Features */

.features {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%; 
  align-content: flex-start;
}

.feature-box {
  display: flex;
  align-items: flex-start;
  text-align: left;
  background-color: #FFF;
  border: 5px solid rgba(0,0,0,0.15);
  border-radius: 15px;
  padding: 30px 25px;
  width: calc(33.3333% - 10px);
  box-sizing: border-box;
}

.feature-icon {
  font-size: 2em;
  color: #000;
  margin-right: 15px;
  margin-top: 5px;
  background: #e4ffb4;
  padding: 14px;
  border-radius: 15px;
  border: 3px solid rgba(0,0,0,0.015)
}

.feature-content h3 {
  margin: 0 0 5px 0;
  font-size: 1.2em;
  line-height: 1.05em;
  color: #000;
  font-family: "Outfit";
  font-weight: 500;
}

.feature-content p {
  margin: 0;
  font-size: 1.025em;
  color: #333;
  letter-spacing: 0.3px;
  line-height: 1.2em;
}

@media screen and (max-width: 1210px){
  .features-component {
    padding: 0 50px 20px;
  }
}

@media screen and (max-width: 1150px){
  .features-component {
    padding: 0 50px 2rem;
  }
  .unique-message {
    padding: 15px;
    font-size: 16px;
  }
  .features-component .tabs button {
    font-size: 16px;
  }
  .features-component .feature-icon {
    font-size: 2em;
    margin-right: 20px;
  }
  .feature-content h3 {
    font-size: 1em;
    line-height: 1em;
  }
  .feature-content p {
    line-height: 1.1em;
  }
}

@media screen and (max-width: 980px){
  .features-component .feature-box {
    width: calc(50% - 10px);
  }
  .feature-content p {
    line-height: 1em;
  }
  .features-component .feature-icon {
    margin-right: 15px;
  }
  .example-description h3 {
    font-size: 1.3em;
  }
  .example-box {
    font-size: 1.025em;
  }
}


@media screen and (max-width: 880px){
  .features-component {
    flex-wrap: wrap;
  }
  .features-component .tabs {
    flex: 100%;
    width: 100%;
    margin-bottom: 0;
    padding-right: 0;
  }
  .features-component .feature-box {
    width: calc(50% - 10px);
  }
  .unique-message p {
    display: inline;
    font-size: 16px;
  }
  .unique-message i {
    font-size: 40px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 750px){
  .unique-message {
    padding: 20px 40px;
  }
  .unique-message i {
    padding-right: 25px;
  }
  .example-image img {
    object-fit: unset;
    height: 100%;
  }
} 

@media screen and (max-width: 650px){
  .features-component .feature-box {
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .feature-content h3 {
    margin-top: 10px;
  }
  .example-box {
    flex-wrap: wrap;
  }
  .example-image, .example-description {
    width: 100%;
  }
}

@media screen and (max-width: 570px){
  .features-component {
    padding: 0 50px 25px;
  }
}

@media screen and (max-width: 500px){
  .feature-content p {
    font-size: 14px;
  }
  .features-component {
    padding: 0 25px 25px;
  }
  .features-component .feature-box {
    padding: 18px;
  }
  .feature-content h3 {
    font-size: 0.9em;
  }
  .unique-message {
    flex-wrap: wrap;
  }
  .unique-message i {
    display: flex;
    margin: 0;
    justify-content: center;
    flex: 100% 0 0;
    margin-bottom: 10px;
    width: 100%;
  }

  .unique-message p {
    text-align: center;
    justify-content: center;
  }

  .unique-message p:first-child {
    padding-bottom: 10px;
  }

}











































.features-component .tabs {
  display: flex;
  gap: 1rem;
}

.features-component .tabs button {
  position: relative;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.features-component .tabs button.active,
.features-component .tabs button:focus {
  transform: scale(1.02);
}

.tab-image-wrapper {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 15px;
}

.tab-image-wrapper img {
  display: block;
  width: 100%;
  transition: transform 0.3s ease;
  -webkit-filter: grayscale(100%); /* For Chrome, Safari, Opera */
  filter: grayscale(100%);         /* Standard syntax for modern browsers */
}

button.active img {
  filter: grayscale(0)
}

.tab-image-wrapper .gradient-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);
  pointer-events: none;
}

.tab-image-wrapper .hover-arrow {
  position: absolute;
  right: -40px;
  bottom: 10px;
  color: #fff;
  font-size: 1.5rem;
  opacity: 0;
  transition: right 0.3s ease, opacity 0.3s ease;
}

.tab-image-wrapper .hover-arrow i {
  background: #e4ffb4;
  color: #000;
  padding: 8px 10px;
  border-radius: 50%;
}

/* Hover Effects */
.features-component .tabs button:hover .tab-image-wrapper img {
  transform: scale(1.05);
}

.features-component .tabs button:hover .hover-arrow {
  right: 10px;
  opacity: 1;
}

/* Always show arrow when active */
.features-component .tabs button.active .hover-arrow {
  right: 10px;
  opacity: 1;
}
