.Hero {
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(87, 4, 134, 0.9);
  }

  /* WHAT'S NEW */


  .Hero .Hero-Checkout-Business p {
    color: #FFF;
    font-size: 21px;
  }

  .Hero .Hero-Checkout-Business i {
    color: gold;
    font-size: 80px;
    margin-bottom: 20px;
  }

  .Hero .Whats-New-Today-Inner {
    padding: 0 50px;
    text-align: center;
  }


  .Hero-Search-Content .Field-Container {
    position: relative;
  }

  .Hero-Search-Content .Field-Container span {
    position: absolute;
    left: -460px;
    top: 0;
    width: 420px;
    border-radius: 10px;
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 500;
    background: #ffa6a6;
    border: 2px solid pink;
    line-height: 1em;
  }

  .Hero-Register-Business h2,
  .Hero-Register-Business input {
    display: flex;
    flex: 100% 0 0;
  }

  .error-message span {
    padding: 10px ;
    display: block;
    font-size: 16px;
  }

  .Hero-Register-Business .Get-Started-Input-Container {
    display: flex;
  }

  .Hero-Register-Business .Get-Started-Input-Container .Get-Started-Prefix-Select {
    display: flex;
    flex: 45% 0;
    border-radius: 20px 0 0 20px;
    background: #eee;
    font-size: 16px;
  }

  .Hero-Register-Business .Get-Started-Input-Container .Get-Started-Input {
    display: flex;
    flex: 55% 0;
    border-radius: 0 20px 20px 0;
    font-size: 16px;
  }

  
  .Hero-content {
    max-width: 750px;
    text-align: left;
    color: #fff;
    justify-content: center;
  }

  .Hero-content a {
    background: #fed600;
    padding: 11px 45px;
    font-size: 21px;
    text-decoration: none;
    color: #000;
    border-radius: 55px;
    font-family: "Outfit";
    align-items: center;
    margin: 0;
    display: inline-flex;
  }

  .Hero-content a:hover {
    background: #000;
    color: #FFF;
  }

  .Hero-content small { 
    font-size: 18px;
    color: #eee; 
  }


  .Hero-content a i {
    margin-left: 6px;
  }

  .Hero-content a:hover {
    background: #222;
    cursor: pointer;
  }

  .Hero-v1-left {
    max-width: 70%;
  }
  
  .Hero h1,
  .Hero-Loading h1 {
    font-size: 1.7rem;
    line-height: 1em;
    margin-bottom: 0.3em;
    margin-top: 0;
    font-family: "Outfit";
    font-weight: 900;
    text-shadow: 1px 1px 50px rgba(0,0,0,0.3)
  }


  
  
  .Hero-Featured-Media {
    flex: 45%;
    display: flex;
    color: #DBF6AC;
    flex-wrap: wrap;
    font-size: 17px;
    justify-content: center;
    font-family: "Outfit";
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .hero-featured-media {
    position: relative;
    width: 100%;
    height: 350px; /* Adjust to your desired height */
    overflow: hidden;
  }
  
  .slides {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .hero-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: fade 12s linear infinite;
  }

  .hero-slide.no-animation {
  animation: none !important;
  opacity: 1;
  }

  .Create-Business-Website .CreateBusinessWebsiteContainer {
      width: 100%;
      font-family: "Outfit";
      font-size: 35px;
      margin: 0;
  }
  
  .hero-slide:nth-child(1) {
    animation-delay: 0s;
  }
  
  .hero-slide:nth-child(2) {
    animation-delay: 6s;
  }
  
  .hero-slide img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  
  @keyframes fade {
    0%, 6.66%, 50%, 56.66%, 100% {
      opacity: 0;
    }
    8.33%, 41.67% {
      opacity: 1;
    }
  }
  
  .Hero-Featured-Media img {
    width: 100%;
    box-sizing: border-box;
  }
  
  .Hero-Main-Section {
    box-shadow: rgb(57, 15, 91) 360px 0px 0px inset;
  }
  
  .Hero-content {
    text-align: left;
    color: #fff;
    justify-content: center;
    flex: 55% 0;
    position: relative;
    padding: 0 50px 0 10px;
    box-sizing: border-box;
    justify-content: right;
  }

  .Main-Heading-Subtitle {
    font-size: 1.7rem;
    display: flex;
    color: #def9af;
  }
  
  .Hero h2,
  .Hero-Loading h2 {
    font-size: 1.6em;
    line-height: 1.1em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-top: 0;
    font-family: "Outfit";
    font-weight: 500;
    text-shadow: 1px 1px 100px rgba(0,0,0,0.3);
  }

  .Hero .divider,
  .Hero-Loading .divider {
    height: 3px;
    background: #a4ce59;
    width: 15%;
    margin: 1.2em 0;
  }
  
  .Hero p {
    font-size: 1.35em;
    margin-bottom: 2rem;
    margin-top: 0;
    font-family: "Outfit";
    font-weight: 300;
  }
  
  .Hero-cta {
    display: inline-block;
    background-color: #b5003b;
    color: #fff;
    border: none;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .Hero-cta:hover {
    background-color: #dd4175;
  }

  .hero-tool-search-container {
    display: flex;
    flex: 100% 0;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .hero-tool-search-container input {
    display: flex;
    flex: 85% 0;
    border-radius: 15px 0 0 15px;
    font-size: 20px;
    padding: 10px 25px;
    font-family: "Outfit";
    box-sizing: border-box;
  }
  
  .hero-tool-search-container button {
    display: flex;
    box-sizing: border-box;
    flex: 15% 0;
    background: #a2c860;
    color: #FFF;
    font-family: "Outfit";
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 0;
    font-size: 20px;
    border-radius: 0 15px 15px 0;
    cursor: pointer;
  }

  .CreateBusinessWebsite-Loading {
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 600px;
    background-image: linear-gradient(to top, #5a1985 70%, #671c91 85%);
  }

  .CreateBusinessWebsiteContainer {
    flex: 100% 0;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    justify-content: center;
    min-height: 425px;
    background-size: cover;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-bottom: 50px;
  }

  .CreateBusinessWebsite-Loading.Step-1 .CreateBusinessWebsiteContainer {
    padding-top: 50px;
    padding-bottom: 30px;
  }
  
  .CreateBusinessWebsite-Loading.Step-1 h2 {
    line-height: 1em;
  }

  .CreateBusinessWebsite-Loading.Step-2 .CreateBusinessWebsiteContainer,
  .CreateBusinessWebsite-Loading.Step-3 .CreateBusinessWebsiteContainer {
    padding-top: 20px;
  }

  .CreateBusinessWebsite-Loading.Step-3 {
    min-height: 600px;
  }

  .CreateBusinessWebsite-InnerHeadingContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  /* Loading - Spinner */

  .ai-website-generation-loading {
    display: flex;
    align-items: flex-start;
    padding-top: 100px;
    height: 500px; /* Full-page centering */
    font-family: Arial, sans-serif;
    font-size: 16px;
    justify-content: center;
    color: #fff; /* Neutral text color */
    font-family: "Outfit";
    flex-wrap: wrap;
    align-content: flex-start;
    font-size: 21px;
  }
  
  .ai-website-generation-loading i {
    font-size: 70px;
    width: 100%;
    color: #FFF;
    margin-bottom: 10px;
    color: #5cad44; /* Spinner color */
  }
  

  /* Create a business account */

  .Create-Your-Business-Bloc-Account {
    padding-top: 50px;
    display: flex;
    flex: 100% 0 0;
    flex-wrap: wrap;
    text-align: center;
  }

  .Create-Your-Business-Bloc-Account .Register-Form-Details {
    display: flex;
    flex: 50%;
    width: 50%;
    flex-wrap: wrap;
    align-content: flex-start;
    box-sizing: border-box;
  }

  .Create-Your-Business-Bloc-Account .Register-Form-Details span,
  .Create-Your-Business-Bloc-Account .Register-Form-Details h3 {
    text-align: left;
  }

  .Create-Your-Business-Bloc-Account .Register-Form-Details h3 {
    font-size: 33px;
    line-height: 1em;
  }

  .Create-Your-Business-Bloc-Account .Register-Form-Fields {
    display: flex;
    flex: 50%;
    width: 50%;
    flex-wrap: wrap;
  }

  .Create-Your-Business-Bloc-Account .Register-Form-Fields .Field-Container {
    flex-wrap: wrap;
  }

  .Create-Your-Business-Bloc-Account .Register-Form-Fields .Field-Container label {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .Create-Your-Business-Bloc-Account .Register-Form-Fields h3 {
    color: #e4ffb4;
    text-align: left;
    font-size: 26px;
  }

  .Create-Your-Business-Bloc-Account .Register-Form-Fields .Field-Container {
    padding: 7px 0;
  }

  .CreateBusinessWebsiteContainer .Create-Your-Business-Bloc-Account .Register-Form-Fields button {
    width: 100%;
    margin: 0 !important;
  }

  .Create-Your-Business-Bloc-Account h3 {
    font-family: "Outfit";
    margin: 0;
    font-size: 22px;
  }

  .Create-Your-Business-Bloc-Account span {
    font-size: 20px;
    margin-bottom: 20px;
    width: 100%;
    justify-content: center;
  }

  .Create-Your-Business-Bloc-Account .Field-Container {
    width: 100%;
    flex: 100%;
    display: flex;
    padding: 10px 10px;
    box-sizing: border-box;
  }

  .Create-Your-Business-Bloc-Account .Field-Container input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-family: "Outfit";
  }

  .Create-Your-Business-Bloc-Account button {
    width: 100%;
    padding: 10px;
    margin: 0 10px 0 10px !important;
  }

  /* Search industry box */

.SearchBox ul {
  position: relative;
}

.Step-One-Section .fa-arrow-right {
  position: absolute;
  top: 10%;
  right: 25px;
}

.business-arrow-container {
  position: relative;
}

.CreateBusinessWebsite-Loading.Step-1 .CreateBusinessWebsiteContainer {
  position: relative;
  align-content: flex-start;
}

.SearchBox {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 10px;
  width: 100%;
}

.items-container {
  overflow-x: HIDDEN; /* Allow horizontal scrolling */
  white-space: nowrap; /* Prevent line breaks */
  width: 100%; /* Adjust as needed */
}

.ListOfItems ul {
  display: flex; /* Use flex for horizontal alignment */
  padding: 0;
  margin: 0;
}

.ListOfItems ul li {
  flex: 0 0 auto; /* Prevent shrinking */
  width: 25%; /* Adjust to fit your design */
  box-sizing: border-box; /* Include padding in width */
}

.business-type-search-container {
  position: relative;
}



.business-type-search-container i.Next-Business-Arrow:hover {
  background-color: #000;
  cursor: pointer;
}



.SearchBox input {
  padding: 20px 12px;
  width: 80%;
  border-radius: 0;
  font-family: "Outfit";
  font-size: 16px;
  border-radius: 10px 0 0 10px;
}

.SearchBox button {
  width: 20%;
  padding: 20px 12px !important;
  margin-right: 0 !important; 
  margin-bottom: 0 !important;
  border-radius: 0 10px 10px 0;
}


  /* Spinner */

  .viewContentDetailsSpinner {
    display: flex;
    width: 100%;
    flex: 100% 0 0;
    justify-content: center;
    position: relative;
    align-items: center;
    height: 60px;
  }
  
  .viewContentDetailsSpinner .spinner {
    display: inline-block;
    width: 30px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
    border: 2px solid transparent;
    border-radius: 50%; /* To create a circular shape */
    border-top-color: #e4ffb4; /* Initial border color */
    animation: spin 7s linear infinite; /* Animation */
    margin: 0;
  }

  .viewContentDetailsSpinner .spinner:hover {
    opacity: 0.5;
    transition: opacity border-top-color 2s;
    cursor: pointer;
    transform: scale(1.5);
    border-top-color: #fa427f;
  }

  .viewContentDetailsSpinner:hover::before {
    content: "Website content"; /* Sets the content of the tooltip to the value of the title attribute */
    position: absolute;
    top: 60%;
    left: calc(60%); /* Adjust distance from the element */
    transform: translateY(-50%);
    background-color: white;
    color: black;
    padding: 5px;
    border-radius: 5px;
    font-size: 16px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    z-index: 999; /* Ensure the tooltip appears above other elements */
    white-space: nowrap; /* Prevent line breaks */
    opacity: 0; /* Hide the tooltip by default */
    transition: opacity 0.3s ease; /* Add a smooth transition effect */
  }
  
  .viewContentDetailsSpinner:hover::before {
    opacity: 1; /* Show the tooltip on hover */
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg); /* Start position */
    }
    100% {
      transform: rotate(360deg); /* End position */
    }
  }

  /* Error message container  */

  /*.CreateBusinessWebsiteContainer-Inner .error-message-span {
    width: 100%;
    display: flex;
    flex: 100% 0 0;
    flex-wrap: wrap;
    font-size: 16px;
    margin-top: 0 !important;
    color: #ffef00 !important;
    text-align: left;
    justify-content: flex-start;
    margin-bottom: 0 !important;
  }*/

  /* Structure  */

  .CreateBusinessWebsite-Loading {
    flex: 100% 0;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    justify-content: center;
    min-height: 425px;
    background-size: cover;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: 50px;
  }

  .CreateBusinessWebsite-Loading.Step-7 {
    min-height: 500px;
  }

  .CreateBusinessWebsiteContainer-Inner {
    flex-wrap: wrap;
    display: flex;
  }

  .CreateBusinessWebsiteContainer-Inner h3 {
    width: 100%;
    font-family: "Outfit";
  }

  .CreateBusinessWebsiteContainer-Inner h2 {
    width: 100%;
  }

  .Step-One-Section,
  .Step-Two-Section,
  .Step-Three-Section {
    width: 100%;
  }

  button.Transaction-In-Progress,
  button.Transaction-In-Progress:hover {
    cursor: unset;
    background: #000 !important;
  }

  .Step-One-Section ul li.selected {
    background: #5fb346 !important;
    color: #000 !important;
  }

  .Step-One-Section ul li.selected:hover {
    box-shadow: 2px 2px 50px #36851e inset;
  }

  .CreateBusinessWebsiteContainer .Hero-Register-Business,
  .BusinessFeature {
    flex: 30% 0;
    display: flex;
    position: relative;
    min-height: 555px;
    background: #5a7d19;
    flex-wrap: wrap;
    align-items: center;
    box-shadow: -20px 20px 0px #5aaa43;
    border-radius: 0;
  }

  .Hero-Register-Business i {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 70px;
    z-index: 0;
    color: rgba(0,0,0,0.1);
  }

  .Dynamic-Business-Listing-Count {
    display: flex;
    font-size: 23px;
    font-style: italic;
    color: #e4ffb4;
    text-align: left;
    line-height: 1em;
  }

  .Hero-Register-Business input, .Hero-Register-Business select, .Hero-Register-Business button {
    background: #FFF;
    font-family: "Outfit";
    font-size: 1.3em;
    padding: 10px 25px;
    width: 100%;
    border-radius: 20px;
    margin-bottom: 10px;
    border: 0;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    line-height: 1em;
  }

  .Hero-Register-Business-Error-Message {
    background: #d76a1c;
    color: #FFF;
    padding: 10px 10px 0 10px;
    border-radius: 10px;
    margin-bottom: 15px;
    margin-top: 10px;
  }
  
  .Hero-Register-Business button {
    background-image: linear-gradient(rgba(87, 4, 134, 0.9), rgba(98, 0, 134, 0.88));
    color: #FFF;
    cursor: pointer;
  }

  .Hero-Register-Business button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .Hero .Hero-Register-Business h2,
  .Hero .BusinessFeature h2 {
    color: #FFF;
    font-size: 2em;
    text-align: left;
  }

  .Hero .BusinessFeature h2 {
    text-align: center;
  }

  .Hero .BusinessFeature a {
    background: #FFF;
    font-family: "Outfit";
    font-size: 1.3em;
    padding: 10px 25px;
    width: 100%;
    border-radius: 20px;
    margin-bottom: 10px;
    border: 0;
    font-style: normal;
    text-decoration: unset;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    background-image: linear-gradient(rgba(87, 4, 134, 0.9), rgba(98, 0, 134, 0.88));
    color: #FFF;
    cursor: pointer;
  }

  .Hero .BusinessFeature p {
    color: #FFF;
    font-size: 21px;
  }

  .Hero .BusinessFeature i {
    color: gold;
    font-size: 80px;
    margin-bottom: 20px;
  }

  .Hero .BusinessFeature-Inner {
    padding: 0 30px;
    text-align: center;
  }

  /* Website generation */

  
  .CreateBusinessWebsiteGeneratingStatus {
    display: flex;
    flex: 50% 0 0;
    width: 50%;
    flex-wrap: wrap;
    font-size: 17px;
    font-family: "Outfit";
    padding-top: 25px;
    font-size: 23px;
    font-family: "Outfit";
    text-align: center;
  }

  .something-went-wrong {
    background: #000;
    width: 100%;
    text-align: center;
    padding-bottom: 100px;
    padding-top: 40px;
    border-radius: 0 0 15px 15px;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
  }

  .something-went-wrong i {
    margin-right: 5px;
  }

  /* Select a draft */

  .select-a-draft-navigator {
    background: #000;
    padding: 8px 15px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 15px 15px 0 0 ;
    align-content: flex-start;
  }

  .select-a-draft-navigator i {
    margin-right: 7px;
  }

  .select-a-draft-navigator select {
    background: #555;
    border: 0;
    border-radius: 15px;
    color: #FFF;
    font-family: "Outfit";
    padding: 2px 15px;
    font-size: 13px;
    cursor: pointer;
}

.select-a-draft-navigator select:hover {
  opacity: 0.8;
}

.select-a-draft-navigator select option {
  font-family: "Arial";
}

.select-a-draft-navigator label, .select-a-draft-navigator select {
  display: flex;
  flex: 1 1;
  align-items: center;
  }

  .select-a-draft-navigator label,
  .select-a-draft-navigator select {
    display: flex;
    flex: 1;
  }

  .CreateBusinessWebsiteContainer-Inner .CreateBusinessWebsitePaymentFormDetails a.change-design-settings {
    margin: 0;
    font-size: 16px;
    padding: 16px 20px;
    margin-bottom: 10px;
    background: #e4ffb4;
    color: #000;
    height: 0;
    margin-top: -30px;
  }

  .CreateBusinessWebsiteContainer-Inner .CreateBusinessWebsitePaymentFormDetails a.change-design-settings:hover {
    background: #a2c860;
  }





  .CreateBusinessWebsiteContainer-Inner.Active-Plan h2 {
    margin-bottom: 10px;
  }
  
  /* Payment form */

  .Create-Business-Website .Draft-Generated-Next-Steps h3 {
    margin: 0;
    margin-bottom: 10px;
  }

  .Create-Business-Website .Draft-Generated-Next-Steps {
    align-content: center;
    padding-right: 50px;
    box-sizing: border-box;
    text-align: justify;
  }

  .Create-Business-Website .Draft-Generated-Next-Steps a {

  }

  .Create-Business-Website .Draft-Generated-Next-Steps .Launch-Hosting-Button {
    background: #fed600;
    margin-left: 10px;
    color: #000;
  }

  .Launch-Hosting-Button .small-screen-generate-text {
    display: none;
  }

  .Launch-Hosting-Button .large-screen-generate-text,
  .Launch-Hosting-Button .small-screen-generate-text {
    color: #000;
    margin: 0;
  }

  .Create-Business-Website .Draft-Generated-Next-Steps .Launch-Hosting-Button i {
    color: #000;
  }

  .CreateBusinessWebsitePaymentFormDetails {
    display: flex;
    flex: 50% 0 0;
    width: 50%;
    flex-wrap: wrap;
    font-size: 17px;
    font-family: "Outfit";
    padding-top: 25px;
    text-align: left;
    justify-content: flex-start;
    padding-right: 5%;
    box-sizing: border-box;
  }

  .CreateBusinessWebsitePaymentFormDetails img {
    width: 100%;
    border-radius: 0 0 5px 5px;
    text-align: right;
    justify-content: flex-end;
    margin-bottom: 10px;
    display: flex;
  }

  .CreateBusinessWebsitePaymentFormDetails img {
    animation: glowEffect 7s ease-in-out infinite;
    animation-delay: 3s; /* Delay between each glow effect */
}

  .CreateBusinessWebsitePaymentFormDetails img:hover {
    opacity: 1;
  }

/* Define the glow effect animation */
@keyframes glowEffect {
    0%, 100% {
        opacity: 1; /* No glow at start and end */
    }
    50% {
        opacity: 0.8; /* Peak glow effect */
    }
}

/* Set up timing for the animation */
.CreateBusinessWebsitePaymentFormDetails img {
    animation: glowEffect 7s ease-in-out;
    animation-iteration-count: infinite;
    animation-delay: 30s;
}

  .CreateBusinessWebsitePaymentFormDetails p {
    padding-bottom: 0;
    margin-bottom: 0;
    line-height: 1.1em;
    font-size: 16px;
    text-align: justify;
    color: #d6bee3;
  }

  .CreateBusinessWebsitePaymentForm p.terms {
    color: #d6bee3;
    font-size: 16px;
    border-top: 2px solid rgba(255,255,255,0.1);
    margin-top: 10px;
    padding-top: 10px;
  }

  .green-highlight {
    color: #e4ffb4;
    font-size: 16px !important;
    margin: 0 !important;
  }

  .CreateBusinessWebsitePaymentForm {
    display: flex;
    flex: 50% 0 0;
    width: 50%;
    flex-wrap: wrap;
    align-content: flex-start;
    padding-top: 25px;
  }

  .generating-website-design-image {
    width: 650px;
    margin-bottom: 50px;
    border-bottom: 25px solid #e4ffb4;
    padding-bottom: 5px;
    border-radius: 50px;
    animation: floatAnimation 1s infinite alternate ease-in-out; /* Adjust the duration and easing function as needed */
    background: #5a1985;
  }

  .CreateBusinessWebsite-Loading.Step-6 {
    background: #5a1985;
  }

  @keyframes floatAnimation {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-10px); /* Adjust the distance the element floats */
    }
  }

  .CreateBusinessWebsiteGeneratingDetails {
    display: block ruby;
    flex: 55% 0;
    width: 55%;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    text-align: center;
    padding-top: 25px;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }

  .CreateBusinessWebsiteGeneratingDetails h3 {
    font-size: 30px;
    font-family: "Outfit";
    font-weight: 400;
    text-align: center;
    margin-top: 0;
  }

  .CreateBusinessWebsiteGeneratingDetails i {
    margin-bottom: -60px;
    font-size: 80px;
    z-index: 1;
    opacity: 0.2;
    margin-right: 7px;
    color: #e4ffb4;
  }

  .CreateBusinessWebsitePaymentForm .checkout-item {
    font-size: 16px;
    border-bottom: 2px solid rgba(255,255,255,0.1);
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .CreateBusinessWebsitePaymentForm i {
    color: #a2c860;
  }

  .CreateBusinessWebsitePaymentForm form, .CreateBusinessWebsitePaymentForm p, .CreateBusinessWebsitePaymentForm h2 {
    display: flex;
    flex: 100% 0 0;
    width: 100%;
    flex-wrap: wrap;
  }

  .CreateBusinessWebsitePaymentForm p {
    font-family: "Outfit";
    font-size: 18px;
    line-height: 1.2em;
    margin-top: 0;
  }

  .CreateBusinessWebsiteContainer-Inner .CreateBusinessWebsitePaymentFormDetails a {
  background: yellow;
  padding: 20px;
  font-size: 21px;
  line-height: 1em;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #FFF;
  border-radius: 5px;
  font-family: "Outfit";
  margin-top: -70px;
  box-sizing: border-box;
  z-index: 9;
  color: #000;
  height: 32px;
  margin-left: 20px;
  }

  .CreateBusinessWebsiteContainer-Inner .CreateBusinessWebsitePaymentFormDetails a.regenerate-button {
    background: lightskyblue
  }

  .CreateBusinessWebsiteContainer-Inner .CreateBusinessWebsitePaymentFormDetails a.regenerate-button:hover {
    background: lightskyblue   
  }
  

  .CreateBusinessWebsiteContainer-Inner .CreateBusinessWebsitePaymentFormDetails a:hover {
    background: inherit;
    background: yellow;
    opacity: 0.8;
  }

  .CreateBusinessWebsitePaymentFormDetails p a {
    padding: unset;
    font-size: 16px;
    line-height: 1em;
    margin: 0 !important
  }

  .CreateBusinessWebsitePaymentForm i {
    margin-right: 7px;
  }

  .CreateBusinessWebsitePaymentForm .StripeElement {
    height: unset !important;
    margin-bottom: 10px;
  }

  .CreateBusinessWebsite-Loading.Step-3 form {
    width: 100%;
  }

  .Business-Website-Additional-Data {
    min-height: 400px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }

  .Business-Website-Additional-Data.Wrap {
    flex-wrap: wrap;
  }

  .Business-Website-Additional-Data .Field-Container {
    position: relative;
    box-sizing: border-box;
    width: 100%;
  }

  .Business-Website-Additional-Data .Field-Container.Business-Detail {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 10px;
  }  

  .Business-Website-Additional-Data .Field-Container.Business-Detail label {
    font-size: 18px;
    width: 170px;
    display: flex;
    align-items: center;
    padding-right: 5%;
    box-sizing: border-box;
    line-height: 1em;
  }

  .Business-Website-Additional-Data .Field-Container.Business-Detail input,
  .Business-Website-Additional-Data .Field-Container.Business-Detail textarea {
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    font-family: "Outfit";
    border-radius: 5px;
    box-sizing: border-box;
    padding: 15px;
    border: 0;
  }

  .Business-Website-Additional-Data .Field-Container.Col-50 {
    width: 50%;
    flex-wrap: wrap;
    display: flex;
    padding: 0;
    align-content: flex-start;
  }

  .Business-Website-Additional-Data .Field-Container.Col-50:last-child label {
    padding-left: 5%;
  }

  .Business-Website-Additional-Data .Field-Container.Col-50 label {
    width: 170px;
    padding-right: 5%;
    box-sizing: border-box;
    align-items: center;
  }

  .Business-Website-Additional-Data .Field-Container.Col-50 .input-icon-container {
    flex: 1;
  }

  .Business-Website-Additional-Data label {
    font-size: 18px;
  }

  .Business-Website-Additional-Data .Field-Container.Wrap {
    flex-wrap: wrap;
  }

  .Business-Website-Additional-Data h3 {
    font-family: "Outfit";
    font-size: 21px;
    line-height: 1.3em;
    font-weight: 500;
    margin-top: 5px;
  }

  .Business-Website-Additional-Data .Field-Container.Col-50 input {
    width: 100%;
    flex: 100% 0 0;
    display: flex;
    padding: 15px 25px 15px 50px;
    font-family: "Outfit";
    box-sizing: border-box;
    font-size: 16px;
  }

  .Business-Website-Additional-Data .Field-Container span,
  .CreateBusinessWebsiteContainer-Inner .error-message-span,
  .Hero-Register-Business-Error-Message {
    width: 100%;
    border-radius: 10px;
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 500;
    background: #ffa6a6;
    border: 2px solid pink;
    line-height: 1em;
    color: #000;
    margin-bottom: 10px;
  }

  .Hero-Register-Business-Error-Message {
    margin-bottom: 0;
  }

  .CreateBusinessWebsiteContainer-Inner .Register-Form-Fields .error-message-span {
    margin-bottom: 0;
  }

  .Hero-Register-Business h2,
  .Hero-Register-Business input {
    display: flex;
    flex: 100% 0 0;
  }

  .error-message span {
    padding: 10px ;
    display: block;
    font-size: 16px;
  }

  .Hero-Register-Business .Get-Started-Input-Container {
    display: flex;
  }

  .Hero-Register-Business .Get-Started-Input-Container .Get-Started-Prefix-Select {
    display: flex;
    flex: 45% 0;
    border-radius: 20px 0 0 20px;
    background: #eee;
    font-size: 16px;
  }

  .Hero-Register-Business .Get-Started-Input-Container .Get-Started-Input {
    display: flex;
    flex: 55% 0;
    border-radius: 0 20px 20px 0;
    font-size: 16px;
  }


  .Header-Options, .Footer-Options, .Color-Options, .Logo-Upload, .Font-Options {
    flex-wrap: wrap;
    display: flex;
    padding-top: 0 !important; 
    flex: 100% 0 0;
    width: 100%;
    justify-content: center;
    align-content: flex-start;
  }

  .Color-Options .Color-Picker {
    display: flex;
    flex: 100%;
    width: 100%; 
    justify-content: center;
  }

  .Color-Options .Color-Picker .Selected-Color-Preview {
    border: 1px solid rgba(0,0,0,0.1);
    box-sizing: border-box;
  }

  .Color-Options .Color-Picker .Selected-Color-Preview:hover {
    opacity: 0.85;
  }

  .CreateBusinessWebsiteContainer .Color-Options .Color-Picker label {
    flex: unset;
    width: 80%;
    margin-right: 0;
    color: #000;
    text-align: left;
    justify-content: left;
    padding-left: 15px;
    margin-bottom: 0;
    border-bottom: 1px solid #DDD;
  }


  

  .CreateBusinessWebsiteContainer .Header-Options h2,
  .CreateBusinessWebsiteContainer .Footer-Options h2,
  .CreateBusinessWebsiteContainer .Page-Selection h2,
  .CreateBusinessWebsiteContainer .Color-Options h2,
  .CreateBusinessWebsiteContainer .Logo-Upload h2,
  .CreateBusinessWebsiteContainer .Font-Options h2 {
    font-size: 37px;
    font-family: "Outfit";
    font-weight: 700;
    width: 100%;
    flex: 100% 0 0;
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .CreateBusinessWebsiteContainer .Color-Options label,
  .CreateBusinessWebsiteContainer .Font-Options label,
  .CreateBusinessWebsiteContainer .Logo-Upload label {
    font-size: 16px;
    display: flex;
    align-items: center;
    align-content: center;
    flex: 100%;
    justify-content: center;
    margin-bottom: 10px;
    padding: 10px 10px 10px 10px;
    color: #000;
    border-bottom: 1px solid #DDD;;
  }

  .CreateBusinessWebsiteContainer .Logo-Upload label {
    flex-wrap: wrap;
  }

  .CreateBusinessWebsiteContainer .Font-Options label {
    margin-bottom: 0;
  }

  .CreateBusinessWebsiteContainer .Page-Options select {
    background: #EEE;
  }

  .Page-Selection input {
    width: 100%;
    box-sizing: border-box;
  }

  .Logo-Upload .business-logo-uploaded-info,
  .Logo-Upload .upload-placeholder {
    width: 100%;
  }

  .Logo-Upload .business-logo-uploaded-info p,
  .Logo-Upload .upload-placeholder p {
    font-size: 13px;
    color: #333;
    padding-top: 11px;
  }

  .Logo-Upload .business-logo-uploaded-info button,
  .Logo-Upload .upload-placeholder button {
    margin: 0;
    margin-top: 7px;
    padding: 4px 12px;
    border-radius: 10px;
  }

  .Logo-Upload .business-logo-uploaded-preview-container img {
    display: flex;
    width: 100%;
    height: 80px;
    object-fit: contain;
  }

  .Logo-Upload input {
    background: #FFF;
    font-family: "Outfit";
    color: #000;
    width: 100%;
  }

  .Logo-Upload p {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 5px;
  }

  .CreateBusinessWebsiteContainer .Color-Options input,
  .CreateBusinessWebsiteContainer .Font-Options select {
    margin-left: 10px;
    border: 0;
    font-family: "Outfit", Arial;
  }
  

  .CreateBusinessWebsiteContainer .Color-Options input {
    padding: 0;
  }

  .CreateBusinessWebsiteContainer .Font-Options select {
    padding: 10px 12px;
    width: 100%;
    box-sizing: border-box;
  }

  .button-actions {
    display: flex;
    flex: 100% 0 0;
    width: 100%;
    justify-content: right;
  }

  .BusinessWebsiteDesignPreview-Column {
    width: 40%;
    flex: 40% 0 0;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-content: flex-start;
  }

  .BusinessWebsiteDesignPreview-Column select {
    width: 100%;
    height: 25px;
    margin-bottom: 15px;
  }

  .BusinessWebsiteDesignPreview {
    display: flex;
    position: relative;
  }

  .Page-Selection {
    width: 20%;
    display: flex;
    flex: 20% 0 0;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    text-align: center;
    padding: 0;
    box-sizing: border-box;
  }

  .Page-Selection .BusinessWebsiteStructureOptions-Inner {
    background: #FFF;
    margin: 10px 0;
  }


  .Page-Selection h2,
  .BusinessWebsiteStructureOptions h2 {
    margin: 0 !important;
    font-family: "Outfit";
  }

  .CreateBusinessWebsiteContainer .Footer-Options h2,
  .CreateBusinessWebsiteContainer .Page-Selection h2,
  .CreateBusinessWebsiteContainer .Color-Options h2,
  .CreateBusinessWebsiteContainer .Font-Options h2,
  .CreateBusinessWebsiteContainer .Logo-Upload h2 {
    margin-top: 20px;
    display: block;
  }

  .BusinessWebsiteStructureOptions.col-40 {
    flex: 40% 0 0;
    width: 40%;
  }

  .BusinessWebsiteStructureOptions.col-20 {
    flex: 20% 0 0;
    width: 20%;
  }

  .BusinessWebsiteStructureOptions.no-padding {
    padding: 0;
  }

  .button-actions .Previous-Button {
    position: absolute;
    left: 0;
    top: 50%;
    margin: 0;
    border-radius: 0 20px 20px 0;
    padding-right: 15px;
  }

  .button-actions .Previous-Button:before {
    content: "\f053";
    font-family: var(--fa-style-family,"Font Awesome 6 Free");
    font-weight: var(--fa-style,900);
    margin-right: 6px;
  }

  .button-actions .Next-Button {
    position: absolute;
    right: 0;
    top: 50%;
    margin: 0;
    border-radius: 20px 0 0 20px;
    padding-left: 15px;
  }

  .button-actions .Next-Button.disabled,
  .button-actions .Next-Button.disabled:hover {
    cursor: not-allowed;
    background: #000 !important;
    color: #FFF !important;
    opacity: 0.3;
  }

  .button-actions .Next-Button.disabled:hover .button-action-text {
    color: #FFF !important;
  }

  .button-actions .Next-Button:after {
    content: "\f054";
    font-family: var(--fa-style-family,"Font Awesome 6 Free");
    font-weight: var(--fa-style,900);
    margin-left: 6px;
  }

  .BusinessWebsiteStructureOptions {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    padding: 0 0.5em;
    box-sizing: border-box;
  }

  .BusinessWebsiteStructureOptions .BusinessWebsiteStructureHeading {
    display: flex;
  }

  .BusinessWebsiteStructureOptions span.BusinessWebsiteStructureTitle{
    color: #000;
    text-align: left;
    margin: 0;
    font-weight: 500;
    font-size: 21px;
    background: #eee;
    box-sizing: border-box;
    display: flex;
    flex: 1;
  }

  .Business-Subcategory-Type {
    display: flex;
    flex: 100% 0 0;
    width: 100%;
  }

  .Business-Subcategory-Type select {
    margin: 0;
    width: 100%;
    background: #FFF;
    border: 0;
    height: 40px;
    padding-left: 20px;
    font-family: "Outfit";
    font-size: 16px;
    cursor: pointer;
  }

  .Business-Subcategory-Type select option {
    font-family: "Arial";
  }

  .Business-Subcategory-Type select:hover {
    opacity: 0.7;
  }

  .BusinessWebsiteStructureHeading span.edit-pages {
    margin: 0;
    width: 20%;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    color: #641b8e;
  }

  .BusinessWebsiteStructureHeading span.edit-pages:hover {
    opacity: 0.85;
    cursor: pointer;
  }

  .CreateBusinessWebsiteContainer .popup {
    position: absolute;
    width: 800px;
    align-content: flex-start;
    top: 25%;
    height: unset;
  }

  .CreateBusinessWebsiteContainer .custom-select-container {
    display: flex;
    flex-wrap: wrap;
  }

  .CreateBusinessWebsiteContainer .custom-select-container label.custom-checkbox {
    display: flex;
    width: 50%;
    align-items: center;
    border: 1px solid #DDD;
    box-sizing: border-box;
    padding: 10px 5px;
  }
  
  .CreateBusinessWebsiteContainer .custom-select-container label.custom-checkbox input {
    width: unset;
  }

  .CreateBusinessWebsiteContainer .custom-select-container .checkbox-custom-text {
    margin-top: 0 !important;
    color: #000;
    font-size: 16px;
    padding-left: 7px;
    line-height: 1em;
  }

  .CreateBusinessWebsiteContainer .tab-description {
    background: #FFF;
    border: 1px solid #DDD;
    border-top: 0;
    height: 400px;
    padding: 20px;
    box-sizing: border-box;
  }

  .CreateBusinessWebsiteContainer .popup .tab-content {
    padding: 20px;
    box-sizing: border-box;
    background: #FFF;
  }

  .CreateBusinessWebsiteContainer .popup button {
    margin: 0;
    color: #FFF;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 50px;
  }

  .CreateBusinessWebsiteContainer .popup .tab-content h3 {
    padding: 0;
    font-size: 27px;
    margin: 0;
    text-align: left;
    justify-content: flex-start;
  }

  .CreateBusinessWebsiteContainer .popup .tab-content p {
    font-size: 18px;
    text-align: left;
    justify-content: flex-start;
    margin-top: 2px;
  }

  .CreateBusinessWebsiteContainer-Inner .popup {
    margin: 0;
    padding: 0;
    background: transparent;
    z-index: 999;
    overflow: unset;
    position: fixed;
  }

  .CreateBusinessWebsiteContainer .popup .tabs {
    justify-content: unset;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .CreateBusinessWebsiteContainer .popup .tabs li {
    margin: 0;
    padding: 10px 0;
  }

  .CreateBusinessWebsiteContainer .popup .popup-content {
    width: 100%;
  }

  .CreateBusinessWebsiteContainer .popup-opacity {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
  }

  .BusinessWebsiteStructureOptions .BusinessWebsiteStructureOptions-Inner {
    background: #FFF;
    margin: 10px 0;
    width: 100%;
  }

  .BusinessWebsiteStructureOptions .BusinessWebsiteStructureOptions-Inner:first-child,
  .Page-Selection .BusinessWebsiteStructureOptions-Inner:first-child {
    margin-top: 0;
  }

  .BusinessWebsiteStructureOptions .BusinessWebsiteStructureOptions-Inner h2,
  .Page-Selection .BusinessWebsiteStructureOptions-Inner h2 {
    color: #000;
    text-align: left;
    padding-bottom: 8px; 
    font-weight: 500;
    font-size: 21px;
    background: #eee;
    padding: 10px 20px;
    box-sizing: border-box;
  }

  .CreateBusinessWebsiteContainer button {
    display: flex;
    background: #000;
    margin: 0 10px 0 0;
    padding: 10px 12px;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    font-family: "Outfit";
    display: inline-block;
    margin-bottom: 10px;
    border: 0;
  }

  .CreateBusinessWebsiteContainer button:hover, .CreateBusinessWebsiteContainer button.selected {
    background: #5fb346 !important;
    cursor: pointer;
    color: #000 !important;
  }

  .business-type-search-container ul li.selected {
    background: #5fb346 !important;
    cursor: pointer;
    color: #000 !important;    
  }

  /* Additonal Data */

  .Business-Website-Additional-Data textarea {
    width: 100%;
    height: 150px;
    font-family: "Outfit";
    padding: 20px;
    box-sizing: border-box;
  }

  /* Page options */

  .establishing-stripe-connection {
    font-size: 18px;
  }

  .Page-Options {
    width: 100%;
    display: flex;
    flex: 100%;
    flex-wrap: wrap;
    box-sizing: border-box;
  }

  .Page-Options label {
    font-size: 16px;
    width: 50%;
  }
  
  .page-option-container {
    box-sizing: border-box;
    color: #000;
    font-family: "Outfit";
    width: 100%;
  }

  .page-option-header {
    background: #EEE;
    padding: 0 15px;
    height: 50px;
    align-items: center;
    width: 100%;
    display: flex;
    flex:100% 0 0;
    border: 1px solid #CCC;
    box-sizing: border-box;
    line-height: 1em;
  }

  .page-option-header input {
    cursor: pointer;
  }

  .input-icon-container {
    position: relative;
    overflow: hidden;
  }

  .input-icon-container i {
    position: absolute;
    left: 10px;
    top: 15px;
    color: #000;
    font-size: 24px;
    line-height: 1em;
  }

  .page-option-container h3  {
    color: #000;
    padding: 0 20px;
    background: #EEE;
  }

  .page-option-container i {
    margin-right: 5px;
    margin-left: 10px;
    font-size: 20px;
  }

  .page-option-container p {
    font-family: "Outfit";
    font-size: 15px;
    padding: 10px;
    border-top: 1px solid #DDD;
    line-height: 1em;
    margin-top: 0;
    display: none;
  }

  /* Webiste Design Preview */

  .BusinessWebsiteDesignPreview {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }

  .headerDesignPreview {
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 15px;
    width: 100%;
    border-radius: 0;
  }

  .transparent-header-active .headerDesignPreview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    background: transparent !important
  }

  .non-transparent-header-active .headerDesignPreview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    background: #eee;
  }

  /*.transparent-header-active .simulatedHero {
    height: 190px;
  }

  .transparent-header-active .contentDesignPreview {
    height: 240px;
  }*/

  .transparent-header-active .contentDesignPreview h2,
  .non-transparent-header-active .contentDesignPreview h2 {
    margin-top: 30px;
  }



  .headerDesignPreview .logoPreview {
    margin: 0 !important;
    width: 30px;
    height: 30px;
    display: flex;
  }


  .headerDesignPreview .logoPreview img {
    object-fit: contain;
    display: flex;
    width: 100%;
    max-width: 50px;
    max-height: 30px;
  }

  .simulatedHero {
    height: 200px;
    background: 333;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    align-content: center;
    background: #444;
  }

  .footerDesignPreview {
    background: #eee;
    height: 120px;
    width: 100%;
    flex-wrap: nowrap;
    display: flex;
  }

  .footerDesignPreview .footerDualSection,
  .footerDesignPreview .footerUnified {
    display: flex;
    flex: 100% 0 0;
    flex-wrap: wrap;
  }

  .footerDesignPreview .footerDualSection,
  .footerDesignPreview .footerUnified {
    display: flex;
    flex: 100%;
    flex-wrap: wrap;
  }

  .footerDesignPreview .footerDualSection .footerContent,
  .footerDesignPreview .footerUnified .footerContent {
    height: 100%;
  }

  .footerDesignPreview .footerDualSection .footerContent span,
  .footerDesignPreview .footerUnified .footerContent span {
    margin: 0;
  }

  .footerDesignPreview .footerUnified .footerSubSection {
    background: #eee;
    display: flex;
    flex: 100%;
  }

  .footerDesignPreview .footerSubSection span.copyright {
    margin: 0;
    padding: 4px 0;
  }

  .footerDesignPreview .footerDualSection .footerSubSection {
    width: 100%;
    background: #000;
    color: #FFF;
  }

  .CreateBusinessWebsiteContainer-Inner .footerDesignPreview span {
    font-size: 14px;
    width: 100%;
    flex: 100% 0 0;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .CreateBusinessWebsite-Inner {
    display: flex;
    flex: 100% 0 0;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
     width: 1000px;
  }

  .CreateBusinessWebsite-Inner {
    align-items: center;
  }

  .CreateBusinessWebsite-Inner h3 {
    font-family: "Outfit";
    font-size: 35px;
    line-height: 1em;
    font-weight: 600;
    color: #FFF;
    margin: 0;
  }

  .CreateBusinessWebsite-Inner p {
    width: 100%;
    color: #FFF;
    font-family: "Outfit";
    font-size: 21px;
    line-height: 1.3em;
    font-weight: 500;
    margin-top: 5px;
  }

  .CreateBusinessWebsite-Inner span:before {
    content: "|";
    padding-left: 10px;
    margin-right: 10px;
    opacity: 0.15;
  }

  .CreateBusinessWebsite-Inner span {
    color: #EEE;
    font-style: italic;
    font-size: 25px;
    font-weight: 500;
    font-family: "Outfit";
  }

  .footer-3, .footer-4 {
    display: flex;
    flex: 100% 0 0;
    text-align: center;
    justify-content: center;
  }

  .footer-3 .column {
    display: flex;
    flex: 33.3%;
    width: 33.3%;
  }

  .footer-4 .column {
    display: flex;
    flex: 25%;
    width: 25%;
    justify-content: center;
    text-align: center;
  }

  

  .contentDesignPreview {
    background: #000;
    width: 100%;
  }

  .contentDesignPreview span {
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  
  .CreateBusinessWebsiteContainer-Inner {
    max-width: 1000px;
    width: 1000px;
    text-align: left;
    color: #fff;
    justify-content: center;
  }

  .business-type-search-container {
    max-width: 1010px;
    width: 1010px;
    text-align: left;
    color: #fff;
    justify-content: center;
  }

  

  .CreateBusinessWebsiteContainer-Inner h2 {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .CreateBusinessWebsiteContainer-Inner ul,
  .business-type-search-container ul {
    display: flex;
    flex: 100% 0 0;
    flex-wrap: nowrap;
    padding: 0;
    margin: 15px 0 0;
  }

  
  .business-type-search-container ul .option-container {
    display: flex;
    flex: 0 0 25%; /* Adjust width with padding/margin */
    width: 25%;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 5px;
    box-sizing: border-box;
  }

  .business-type-search-container ul .option-container:nth-child(4n + 1) {
    padding-left: 0;
  }

  .business-type-search-container ul .option-container:nth-child(4n){
    padding-right: 0;
  }

  .Previous-Business-Arrow {
    position: absolute;
    left: -30px;
    top: 25px;
    color: #FFF;
    cursor: pointer;
  }

  .Next-Business-Arrow:hover, .Previous-Business-Arrow:hover {
    opacity: 0.7;
  }

  .Next-Business-Arrow.disabled,
  .Previous-Business-Arrow.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  .Next-Business-Arrow {
    position: absolute;
    right: -30px;
    top: 25px;
    color: #FFF;
    cursor: pointer;
  }

  .CreateBusinessWebsiteContainer-Inner ul li  {
    flex: 0 0 25%; /* Adjust width with padding/margin */
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    background: #000;
    margin: 0 10px 0 0;
    padding: 20px 12px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    font-family: "Outfit";
  }

  .business-type-search-container ul li  {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    background: #000;
    margin: 0 10px 0 0;
    padding: 20px 12px;
    box-sizing: border-box;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    font-family: "Outfit";
    width: 100%;
  }

  .business-type-search-container ul li.option-coming-soon {
    opacity: 0.5;
    cursor: not-allowed !important;
  }

  .business-type-search-container ul li.option-coming-soon:hover {
    background: #000;
    color: #FFF;
  }

  .business-type-search-container ul li:last-child {
    margin-right: 0;
  }

  .CreateBusinessWebsiteContainer-Inner span {
    font-size: 18px;
    font-family: "Outfit";
    display: inline-flex;
    margin-top: 10px;
    align-items: center;
    color: #d6bee3;
  }

  .CreateBusinessWebsiteContainer-Inner span i {
    background: #000;
    padding: 7px 10px;
    border-radius: 50%;
    margin-right: 10px;
    align-items: center;
    display: flex;
  }

  .business-type-search-container ul li i {
    margin-right: 7px;
    font-size: 20px;
  }

  .business-type-search-container ul li:hover {
    background: #5fb346;
    cursor: pointer;
    color: #000;
  }

  .CreateBusinessWebsiteContainer-Inner a {
    background: #000;
    padding: 11px 45px;
    font-size: 21px;
    line-height: 1em;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: #FFF;
    border-radius: 5px;
    font-family: "Outfit";
  }

  .CreateBusinessWebsiteContainer-Inner a i {
    margin-right: 3px;
  }

  .CreateBusinessWebsiteContainer-Inner a:hover {
    background: #222;
    cursor: pointer;
  }

  .Hero-v1-left {
    max-width: 70%;
  }
  
  .Hero h1,
  .CreateBusinessWebsite-Loading h1 {
    font-size: 3.3rem;
    line-height: 1em;
    margin-bottom: 0em;
    margin-top: 0;
    font-family: "Outfit";
    font-weight: 900;
    text-shadow: 1px 1px 50px rgba(0,0,0,0.3);
    width: 100%;
  }
  


  .Hero .divider,
  .CreateBusinessWebsite-Loading .divider {
    height: 3px;
    background: #a4ce59;
    width: 15%;
    margin: 1.2em 0;
  }
  
  .Hero p {
    font-size: 1.35em;
    margin-bottom: 2rem;
    margin-top: 0;
    font-family: "Outfit";
    font-weight: 300;
  }
  
  .Hero-cta {
    display: inline-block;
    background-color: #b5003b;
    color: #fff;
    border: none;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .Hero-cta:hover {
    background-color: #dd4175;
  }


  .CreateBusinessWebsiteContainer-Inner .button-action-text { 
    margin: 0;
    color: #FFF;
  }

  .CreateBusinessWebsiteContainer-Inner .Previous-Button:hover .button-action-text,
  .CreateBusinessWebsiteContainer-Inner .Next-Button:hover .button-action-text {
    color: #000;
  }

  @media screen and (max-width: 1350px){
    .Hero h1, .CreateBusinessWebsite-Loading h1 {
      font-size: 2.82rem;
    }
    .Hero-Search-Content {
      padding: 0 30px;
    }
    .Hero h2, .Hero-Loading h2 {
      font-size: 1.3rem;
    }
    .Hero-Main-Section {
      box-shadow: rgb(57, 15, 91) 300px 0px 0px inset;
    }
  }

  @media screen and (max-width: 1150px){
    .Hero-content {
      max-width: 550px;
    }
    .Hero h1, .Hero-Loading h1 {
      font-size: 2.8rem;
    }
    .Hero h2, .Hero-Loading h2 {
      font-size: 1.4em;
      margin-bottom: 15px;
    }
    .Dynamic-Business-Listing-Count {
      text-align: left;
      line-height: 1em;
      margin-bottom: 5px;
    }
    .Hero .Hero-Register-Business h2, .Hero .Hero-Checkout-Business h2 {
      line-height: 1em;
    }
    .Hero-Search-Content {
      padding: 0 35px;
    }

    .CreateBusinessWebsite-Loading.Step-1 {
      padding: 50px 90px 30px 90px;
    }

    .CreateBusinessWebsite-Loading.Step-2,
    .CreateBusinessWebsite-Loading.Step-3,
    .CreateBusinessWebsite-Loading.Step-4,
    .CreateBusinessWebsite-Loading.Step-6 {
      padding: 50px 90px;
    }

    .CreateBusinessWebsite-Inner {
      width: 100%;
      padding: 0 90px;
      box-sizing: border-box;
    }

    .CreateBusinessWebsiteContainer .popup {
      width: 100%;
      padding: 0 90px;
    }

    .CreateBusinessWebsiteContainer .Font-Options label {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .CreateBusinessWebsiteContainer .Font-Options select {
      margin-left: 0;
      margin-top: 3px;
    }
    .CreateBusinessWebsiteGeneratingDetails {
      width: 100%;
      flex: 100%;
      padding: 0;
    }

    .Create-Your-Business-Bloc-Account .Register-Form-Details {
      padding-right: 45px;
    }
  }
  
  @media screen and (max-width: 980px){
    .Hero .Hero-Register-Business h2, .Hero .Hero-Checkout-Business h2 {
      font-size: 1.5em;
    }
    .Hero .Hero-Checkout-Business p {
      font-size: 18px;
    }
    .Hero .Hero-Checkout-Business a {
      font-size: 1.1em
    }
    .Dynamic-Business-Listing-Count {
      font-size: 18px;
    }
    .Hero-Register-Business input, .Hero-Register-Business select, .Hero-Register-Business button {
      font-size: 0.9em;
    }
    .Hero-Search-Content {
      min-height: unset;
    }
    .BusinessWebsiteDesignPreview { 
      width: 100%; 
      flex: 100% 0; 
    } 
    .BusinessWebsiteDesignPreview-Column {
      flex: 35% 0;
      width: 35%;
    }
    .BusinessWebsiteStructureOptions.col-40 { 
      flex: 45% 0; 
      width: 45%; 
    }
    .BusinessWebsiteStructureOptions span.BusinessWebsiteStructureTitle {
      font-size: 18px;
    }
    .CreateBusinessWebsite-Inner {
      width: 100%;
      box-sizing: border-box;
      padding: 0;
    }
    .CreateBusinessWebsiteGeneratingStatus {
      width: 100%;
      flex: 100%;
    }
    .generating-website-design-image {
      width: 100%;
    }
    .Create-Your-Business-Bloc-Account .Register-Form-Details h3 {
      font-size: 26px;
    }
  }
  
  
  @media screen and (max-width: 880px){
    .Hero h1, .Hero-Loading h1 {
      font-size: 2.3rem;
    }
    .Hero h2, .Hero-Loading h2 {
      font-size: 1.3rem;
    }
    .Hero-content {
      max-width: 430px;
    }
    .Hero-Search-Content {
      padding: 0 25px;
    }
    .BusinessWebsiteDesignPreview {
      display: none;
    }
    .BusinessWebsiteStructureOptions.col-40, .BusinessWebsiteStructureOptions.col-20 {
      width: 50%;
      flex: 50% 0;
    }
    .Business-Website-Additional-Data .Field-Container.Col-50 {
      width: 100%;
      margin-bottom: 10px;
    }
    .Business-Website-Additional-Data .Field-Container.Col-50:last-child {
      margin-bottom: 0;
    }
    .Business-Website-Additional-Data .Field-Container.Col-50:last-child label {
      padding-left: unset;
    }
    .Business-Website-Additional-Data h3, .CreateBusinessWebsite-Inner p {
      font-size: 18px;
      text-align: center;
    }
    .Create-Business-Website .Draft-Generated-Next-Steps {
      padding-right: 5%;
      box-sizing: border-box;
    }
    .CreateBusinessWebsite-Loading.Step-4 .CreateBusinessWebsiteContainer-Inner { 
      flex-direction: row-reverse; 
    }
    .page-option-header {
      font-size: 15px;
      padding: 0 8px;
    }
    .page-option-container i {
      font-size: 16px;
      margin-left: 5px;
    }
    .Create-Your-Business-Bloc-Account .Register-Form-Fields h3 {
      font-size: 21px;
    }


    .business-type-search-container ul .option-container {
      width: 33.3%;
      flex: 0 0 33.3%;
    }
  
    .business-type-search-container ul .option-container:nth-child(4n + 1) {
      padding-left: 0;
    }
  
    .business-type-search-container ul .option-container:nth-child(4n){
      padding-right: 10px;
    }
    
    .business-type-search-container ul .option-container:nth-child(3n + 1) {
      padding-left: 0;
    }
  
    .business-type-search-container ul .option-container:nth-child(3n){
      padding-right: 0;
    }

    .business-type-search-container ul li {
      font-size: 15px;
    }

    .business-type-search-container ul li i {
      font-size: 18px;
    }
    .CreateBusinessWebsitePaymentForm .checkout-item,
    .CreateBusinessWebsitePaymentForm p {
      font-size: 15px;
    }
    .CreateBusinessWebsitePaymentFormDetails {
      padding: 0;
    }
    .BusinessWebsiteDesignPreview-Column {
      display: none;
    }
  }

  @media screen and (max-width: 750px){
    .BusinessWebsiteStructureOptions.col-40, .BusinessWebsiteStructureOptions.col-20 {
      flex: 100%;
      width: 100%;
    }
    .CreateBusinessWebsite-Loading.Step-1 h1 {
      font-size: 40px;
      text-align: center;
    }
    .CreateBusinessWebsite-Loading.Step-1 h2 {
      font-size: 18px;
      text-align: center;
    }
    .CreateBusinessWebsiteContainer-Inner span {
      line-height: 1em;
    }

    .button-actions .Previous-Button, 
    .button-actions .Next-Button {
      position: fixed;
    }
    .BusinessWebsiteStructureOptions {
      padding: 0;
    }
    .Business-Website-Additional-Data .Field-Container.Col-50 {
      flex-wrap: wrap;
    }
    .Business-Website-Additional-Data .Field-Container.Col-50 label, 
    .Business-Website-Additional-Data .Field-Container.Col-50,
    .Business-Website-Additional-Data .Field-Container.Business-Detail label {
      width: 100%;
      margin-bottom: 5px;
    }
    .Business-Website-Additional-Data .Field-Container.Col-50 .input-icon-container {
      width: 100%;
      flex: 100%;
    }
    .Create-Business-Website .Draft-Generated-Next-Steps {
      width: 100%;
      padding-right: 0;
    }
    .CreateBusinessWebsite-Loading.Step-4 .CreateBusinessWebsiteContainer-Inner {
      flex-direction: column-reverse;
    }
    .CreateBusinessWebsiteContainer-Inner .button-action-text {
      display: none;
    }
    .button-actions .Next-Button {
      padding-left: 7px;
    }
    .button-actions .Previous-Button {
      padding-right: 7px;
    }
    .Logo-Upload .business-logo-uploaded-info, .Logo-Upload .upload-placeholder {
      padding-bottom: 15px;
    }
    .Create-Business-Website .Draft-Generated-Next-Steps .Launch-Hosting-Button i,
    .Create-Business-Website .Draft-Generated-Next-Steps .Launch-Hosting-Button:after {
      display: none;
    }
    .Create-Business-Website .Draft-Generated-Next-Steps h3 {
      font-size: 30px;
    }
    .CreateBusinessWebsite-InnerHeadingContainer {
      flex-direction: column-reverse;
    }
    .CreateBusinessWebsite-Inner span::before { 
      display: none;
    }
    .CreateBusinessWebsiteGeneratingStatus {
      width: 100%;
      flex: 100%;
    }
    .Create-Your-Business-Bloc-Account .Register-Form-Details h3 {
      font-size: 20px;
    }

  }

  @media screen and (max-width: 700px){
      /* Register form */
      .Create-Your-Business-Bloc-Account .Register-Form-Details {
        width: 100%;
        flex: 100%;
      }
      .Create-Your-Business-Bloc-Account .Register-Form-Details {
        padding-right: 0;
      }

  }
  
  @media screen and (max-width: 650px){
    /* HERO COLUMNS */
    .Hero-Register-Business, .Hero-Checkout-Business {
      min-height: 400px;
      flex: 35% 0 0;
    }
    /* HERO */
    .Hero-content {
      max-width: 310px;
    }
    .Hero h1, .Hero-Loading h1 {
      font-size: 1.75rem;
    }
    .Hero h2, .Hero-Loading h2 {
      font-size: 1.1rem;
    }
    .Hero .Hero-Checkout-Business p {
      font-size: 15px;
    }
    .Hero .Hero-Checkout-Business i {
      font-size: 50px;
    }
    .Create-Your-Business-Bloc-Account {
      padding-top: 10px;
    }
    /* Popup */
    .CreateBusinessWebsiteContainer .popup .tab-content h3 {
      font-size: 23px;
      line-height: 1em;
    }
    .CreateBusinessWebsiteContainer .popup .tab-content p {
      font-size: 16px;
    }
    .CreateBusinessWebsiteContainer .custom-select-container label.custom-checkbox {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 570px){
    .Hero-Register-Business, .Hero-Checkout-Business {
      flex: 100% 0 0;
    }
    .Hero-Search-Content {
      flex: 100% 0;
    }
    .Hero-Search-Content {
      padding: 0 50px;
    }
    .Home .Hero-Main-Section {
      width: 100%;
    }
    .Hero-Checkout-Business {
      flex: 100% 0 0;
    }
    .Hero .Hero-Checkout-Business i {
      font-size: 80px;
    }
    .Hero .Hero-Checkout-Business p {
      font-size: 18px;
    } 
    .CreateBusinessWebsite-Loading.Step-2,
    .CreateBusinessWebsite-Loading.Step-3,
    .CreateBusinessWebsite-Loading.Step-4,
    .CreateBusinessWebsite-Loading.Step-6 {
      padding: 50px;
    }
    .CreateBusinessWebsiteContainer .popup {
      width: 100%;
      padding: 0 50px;
    }
    .Next-Business-Arrow {
      right: -19px;
      font-size: 20px;
      top: 39px;
    }
    .Previous-Business-Arrow {
      left: -19px;
      font-size: 20px;
      top: 39px;
    }
    .business-type-search-container ul li {
      text-align: center;
      flex-wrap: wrap;
      padding: 15px 12px;
    }
    .business-type-search-container ul li i {
      width: 100%;
      margin: 0;
    }
    .CreateBusinessWebsitePaymentFormDetails,
    .CreateBusinessWebsitePaymentForm {
      width: 100%;
      flex: 100%;
      padding: 0 15px;
    }
    .CreateBusinessWebsitePaymentFormDetails img {
      padding: 0;
    }
  }

  @media screen and (max-width: 1150px){
    .CreateBusinessWebsiteContainer-Inner,
    .business-arrow-container,
    .business-type-search-container {
      max-width: 100%;
    }
    .Hero h1, .CreateBusinessWebsite-Loading h1 {
      font-size: 2.8rem;
    }
    .Hero h2 {
      font-size: 1.4em;
      margin-bottom: 15px;
    }
    .Dynamic-Business-Listing-Count {
      text-align: left;
      line-height: 1em;
      margin-bottom: 5px;
    }
    .Hero .Hero-Register-Business h2, .Hero .BusinessFeature h2 {
      line-height: 1em;
      font-size: 1.3rem;
    }
    .Hero-Search-Content {
      padding: 40px 15px;
    }
    .Hero-Register-Business input, .Hero-Register-Business select, .Hero-Register-Business button {
      font-size: 1rem;
    }
    .Home .Hero-content h1 {
      font-size: 2.5rem;
    }
    .Hero-Main-Section, .Hero-Loading, .Hero-Register-Business, .Hero-Checkout-Business {
      min-height: 500px;
    }
    .Hero-Main-Section {
      box-shadow: rgb(57, 15, 91) 280px 0px 0px inset;
    }
  }
  
  @media screen and (max-width: 980px){
    .CreateBusinessWebsiteContainer, .CreateBusinessWebsite-Loading, .Hero-Register-Business, .BusinessFeature,
    .Hero-Main-Section, .Hero-Loading, .Hero-Register-Business, .Hero-Checkout-Business {
      min-height: 420px;
    }
    .Hero .Hero-Register-Business h2, .Hero .BusinessFeature h2 {
      font-size: 1.5em;
    }
    .Hero .BusinessFeature p {
      font-size: 18px;
    }
    .Hero .BusinessFeature a {
      font-size: 1.1em
    }
    .Dynamic-Business-Listing-Count {
      font-size: 18px;
    }
    .Hero-Register-Business input, .Hero-Register-Business select, .Hero-Register-Business button {
      font-size: 0.9em;
    }
    .Business-Website-Additional-Data {
      min-height: unset;
    }
    .Home .Hero-content h1 {
      font-size: 1.85rem;
    }
    .Main-Heading-Subtitle {
      font-size: 1.3rem;
    }
    .Hero-content small {
      font-size: 16px;
    }
    .Hero-content a {
      padding: 7px 36px;
      font-size: 16px;
    }
    .hero-slide span {
      position: absolute;
      bottom: 50px;
      left: 10px;
      font-size: 16px;
    }
  }
  
  
  @media screen and (max-width: 880px){
    .Hero h1, .CreateBusinessWebsite-Loading h1 {
      font-size: 2.3rem;
    }
    .Hero h2, .CreateBusinessWebsite-Loading h2 {
      font-size: 1.3rem;
    }
    .CreateBusinessWebsiteContainer-Inner ul li {
      font-size: 16px;
    }
    .CreateBusinessWebsite-Loading.Step-1 {
      min-height: unset;
      padding: 50px 90px 80px 90px;
    }
    .CreateBusinessWebsite-Loading.Step-1 h2 {
      font-weight: 500;
      font-size: 24px;
    }
    .CreateBusinessWebsite-Loading.Step-1 .CreateBusinessWebsiteContainer {
      padding-top: 0;
      padding-bottom: 0;
      min-height: unset;
    }
    .Launch-Hosting-Button .large-screen-generate-text {
      display: none;
    }
    .Launch-Hosting-Button .small-screen-generate-text {
      display: inline;
    }
    .CreateBusinessWebsiteContainer, .CreateBusinessWebsite-Loading, .Hero-Register-Business, .BusinessFeature, .Hero-Main-Section, .Hero-Loading, .Hero-Register-Business, .Hero-Checkout-Business {
      min-height: 370px;
    }
    .Hero-Main-Section {
      box-shadow: rgb(57, 15, 91) 200px 0px 0px inset
    }
    .Home .Hero-content {
      padding: 0 15px 0 25px;
    }
    .Hero .Hero-Register-Business h2, .Hero .BusinessFeature h2 {
      font-size: 1.2em;
    }
    .Home .Hero-content h2,
    .Hero-content small {
      font-size: 0.9em;
    }
    .Main-Heading-Subtitle {
      line-height: 1em;
    }
  }

  @media screen and (max-width: 750px){
    .Hero-content small {
      display: none;
    }
    .CreateBusinessWebsiteContainer, .CreateBusinessWebsite-Loading {
      min-height: 380px;
    }
    .Hero-Register-Business, .BusinessFeature {
      min-height: 380px;
    }
    .Hero-Search-Content {
      padding: 0 10px;
    }
    .Home .Hero-Main-Section { flex-wrap: wrap; } .Hero-Featured-Media { flex: 100%; } .Home .Hero-content { flex: 100%; }
    .hero-featured-media {
      height: 200px
    }
    .Hero-Featured-Media,
    .hero-featured-media {
      overflow: unset;
    }
    .Home .Hero-content {
      margin-top: 10px;
      padding-right: 20px;
    }
    .Home .Hero-content h1, .Main-Heading-Subtitle { 
      font-size: 1.5rem;
    }
    .Home .Hero-Main-Section {
      align-content: center;
    }
    .hero-slide img {
      object-position: left;
    }
    .Hero h2 {
      margin-bottom: 10px;
    }
    .hero-slide span {
      bottom: -10px;
    }
    .Home .Hero-content {
      max-width: unset;
      background: #FFF;
      padding: 20px;
      border: 3px solid #000;
      border-radius: 15px;
      flex: unset;
      color: #000;
      margin-top: -30px;
    }
    .Home .Hero-content span {
      color: #000;
    }
    .Home .Hero-content h1, .Main-Heading-Subtitle {
      font-size: 1.2rem;
      margin-bottom: 5px;
    }
    .hero-slide span {
      display: none;
    }
  }
  
  @media screen and (max-width: 650px){
    /* HERO COLUMNS */
    .CreateBusinessWebsiteContainer, .CreateBusinessWebsite-Loading {
      flex: 65% 0 0;
    }
    .Hero-Register-Business, .BusinessFeature {
      flex: 35% 0 0;
    }
    /* HERO */
    .Hero h1, .CreateBusinessWebsite-Loading h1 {
      font-size: 1.75rem;
    }
    .Hero h2, .CreateBusinessWebsite-Loading h2 {
      font-size: 1.1rem;
    }
    .Hero .BusinessFeature p {
      font-size: 15px;
    }
    .Hero .BusinessFeature i {
      font-size: 50px;
    }
  }

  @media screen and (max-width: 600px){
    .CreateBusinessWebsite-Loading.Step-1 h1 {
      font-size: 35px;
    }
    .CreateBusinessWebsite-Loading.Step-1 h2 {
      font-weight: 500;
      font-size: 20px;
    }
    .CreateBusinessWebsite-Loading.Step-1 .CreateBusinessWebsiteContainer-Inner ul li {
      padding: 11px 12px;
    }
    .CreateBusinessWebsite-Loading.Step-1 .SearchBox  {
      margin-top: 20px;
    }
    .CreateBusinessWebsite-Loading.Step-1 .SearchBox button,
    .CreateBusinessWebsite-Loading.Step-1 .SearchBox input {
      padding: 15px 10px !important;
    }
  }
  
  @media screen and (max-width: 570px){
    .Hero-Register-Business, .BusinessFeature {
      flex: 100% 0 0;
    }
    .Business-Website-Additional-Data {
      flex: 100% 0;
    }
    .BusinessFeature {
      flex: 100% 0 0;
    }
    .Hero .BusinessFeature i {
      font-size: 80px;
    }
    .Hero .BusinessFeature p {
      font-size: 18px;
    } 
    .CreateBusinessWebsite-Loading.Step-1 {
      padding: 50px 50px 80px 50px;
    }
    .Home .Hero-Main-Section {
      padding: 50px 0;
      height: 100%;
      min-height: 100%;;
    }
    .Hero .Hero-Register-Business h2, .Hero .BusinessFeature h2 {
      font-size: 1.4rem;
    }
    .Hero-Featured-Media {
      padding: 0 20px;
    }
    .hero-featured-media {
      height: 270px;
    }
    .Hero-Featured-Media img {
      object-position: center;
      height: 100%;
      min-height: 100%;
    }
    .Home .Hero-content {
      margin-top: 10px;
      flex: 100%;
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 500px){
    .CreateBusinessWebsite-Loading.Step-1 h1,
    .CreateBusinessWebsite-Inner h3 {
      font-size: 30px;
      line-height: 1em;
    }
    .CreateBusinessWebsite-Inner span::before {
      display: none;
    }
    .Business-Website-Additional-Data h3, .CreateBusinessWebsite-Inner p {
      font-size: 18px;
    }
    .CreateBusinessWebsite-Loading.Step-1 h2 {
      font-size: 18px;
    }
    .CreateBusinessWebsite-Loading.Step-1 {
      padding: 50px 25px 80px 25px;
    }
  }

  .hero-tool-search-container {
    display: flex;
    flex: 100% 0;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .hero-tool-search-container input {
    display: flex;
    flex: 85% 0;
    border-radius: 15px 0 0 15px;
    font-size: 20px;
    padding: 10px 25px;
    font-family: "Outfit";
    box-sizing: border-box;
  }
  
  .hero-tool-search-container button {
    display: flex;
    box-sizing: border-box;
    flex: 15% 0;
    background: #a2c860;
    color: #FFF;
    font-family: "Outfit";
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 0;
    font-size: 20px;
    border-radius: 0 15px 15px 0;
    cursor: pointer;
  }

/* ANIMATION */

.CreateBusinessWebsiteContainer-Inner,
.CreateBusinessWebsiteContainer-Inner,
.CreateBusinessWebsiteContainer-Inner {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.CreateBusinessWebsiteContainer-Inner.fade-out,
.CreateBusinessWebsiteContainer-Inner.fade-out,
.CreateBusinessWebsiteContainer-Inner.fade-out {
  opacity: 0;
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.CreateBusinessWebsiteContainer-Inner {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.CreateBusinessWebsiteContainer-Inner.fade-out {
  animation: fadeOut 0.5s forwards;
}

.CreateBusinessWebsiteContainer-Inner.fade-in {
  animation: fadeIn 0.5s forwards;
}