/* Footer styles */
.footer {
    background-color: #651c8e;
    color: #fff;
    border-top: 10px solid #6d10a2;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 0 70px;
  }

  .footer-container .footer-column i {
    margin-right: 6px;
  }
  
  .footer-column {
    flex: 1 1 200px;
    max-width: 250px;
    text-align: left;
    font-family: "Outfit";
  }
  
  .footer-column h3 {
    font-size: 1.45rem;
    margin-bottom: 10px;
    line-height: 1em;
    display: flex;
    flex-wrap: nowrap;
    font-weight: 700;
    color: #FFF; /* Accent color for headings */
    align-items: center;
  }

  .footer-column h3 span {
    display: flex;
    flex: 2;
    font-size: 1.7rem;
  }

  .footer-container .footer-column h3 i {
    display: flex;
    background: #FFF;
    color: #651c8e;
    padding: 10px 12px;
    border-radius: 50%;
    font-size: 21px;
    margin-right: 10px;
  }
  
  .footer-column p,
  .footer-column ul {
    font-size: 1.1rem;
    line-height: 1.3;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 100%;
    width: 100%;
  }
  
  .footer-column ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-column li {
    margin: 3px 0;
    width: 100%;
  }
  
  .footer-column a {
    color: #fff;
    text-decoration: none;
    width: 100%;
    flex: 100% 1;
    display: flex;
    align-items: center;
  }

  
  .footer-column a:hover {
    text-decoration: unset;
    opacity: 0.8;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 0;
    font-size: 1.23rem;
    font-family: "Outfit";
    padding: 10px 0;
    background: #e4ffb4;
    color: #b0b0b0;
    box-sizing: border-box;
    box-shadow: 0 -15px 0 #a2c860;
  }

  .footer-bottom p {
    color: #a2c860;
  }