.Spotlight {
  display: flex;
  flex: 100% 0 0;
  flex-wrap:  nowrap;
}

.Get-Started-Content {
  display: flex;
  width: 1200px;
  background: #FFF;
  margin-top: -90px;
  z-index: 2;
  flex-wrap: wrap;
  border: 7px solid #eee;
  border-radius: 20px;
}

.Get-Started-Content-inner {
  padding: 50px;
}


.Get-Started-Content-inner h2, 
.Get-Started-Content-inner p {
  text-align: left;
}


.Get-Started-Content-inner h2 {
  font-family: "Outfit";
  font-size: 35px;
  margin: 0;
}

.Get-Started-Content-inner p {
  font-size: 18px;
  line-height: 1.4em;
}

ul.Get-Started-Step-List {
  list-style: none;
  padding: 0;
}

li.Get-Started-Step-Indicator {
  font-family: "Outfit";
  font-size: 40px;
  font-style: italic;
  display: inline;
}

li.Get-Started-Step-Final-Step {
  font-family: "Outfit";
  font-size: 40px;
  font-style: italic;
  display: inline;
  margin-left: 10px;
  opacity: 0.3;
}

.Get-Started-Input {
  padding: 20px 50px 20px 25px;
  font-family: "Outfit";
  font-size: 21px;
  border: 0;
  flex: 70% 0 0 !important;
  margin: 0;
  border-radius: 0 5px 5px 0;
}

.Next-Step button {
  background: #333;
  color: #fff;
  cursor: pointer;
  width: calc(100% - 10px);
}

.Next-Step button i {
  margin-left: 2px;
}

.Get-Started-Prefix-Select {
  padding: 20px 20px 20px 20px;
  font-family: "Outfit";
  font-size: 21px;
  background: #e4ffb4;
  border: 0;
  border-radius: 10px 0 0 10px;
  flex: 30% 0 0 !important;


}

.Get-Started-Submit {
  padding: 20px 20px 20px 20px;
  font-family: "Outfit";
  color: #FFF;
  font-size: 23px;
  border: 0;
  background: #000;
  border-radius: 0 10px 10px 0;
}

.Get-Started-Account-Details-Container {
  display: flex;
  flex: 100% 0;
  flex-wrap: wrap;
}

.Get-Started-Account-Details-Container .Get-Started-Account-Detail {
  flex: 100% 0;
  flex-shrink: 0;
}

.Get-Started-Account-Details-Container .Get-Started-Account-Detail .Error-Message {
  color: #fffc24;
  font-size: 19px;
  font-weight: 500;
  font-family: "Outfit";
}

.Get-Started-Account-Details-Container .Hero-Register-Business-Error-Message {
  margin-top: 5px;
}


.Get-Started-Account-Details-Container input, .Get-Started-Account-Details-Container button {
  border: 0;
  font-family: "Outfit";
  font-size: 21px;
  margin: 5px;
  padding: 20px 15px;
  width: calc(100% - 40px);
}

.Get-Started-Account-Details-Container input {
  width: calc(100% - 40px);
}

.Get-Started-Account-Details-Container button {
  width: calc(100% - 10px);
}














.Get-Started-Header {
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(87, 4, 134, 0.9);
  flex: 60% 0 0;
}

.Spotlight-Block {
  display: flex;
  flex: 40% 0 0;
  flex-shrink: 0;
  background-size: cover;
  background-position: center center;
}

.Get-Started-Header-Main-Section {
  flex: 100% 0;
  display: flex;
  padding: 80px 60px 150px;
  justify-content: center;
  background-size: cover;
  align-items: center;
}

.Get-Started-Header-Main-Section-Full-Width {
  flex: 100% 0;
  display: flex;
  padding: 120px 0;
  justify-content: center;
  background-size: cover;
  align-items: center;
}


.Page-Header-Search-For-Business h2,
.Page-Header-Search-For-Business input {
  display: flex;
  flex: 100% 0 0;
}

.Get-Started-Input-Container {
  display: flex;
  flex: 100% 0 0;
  margin: 5px;
}

.Get-Started-Header-content {
  max-width: 1200px;
  text-align: left;
  color: #fff;
  justify-content: center;
}

.Get-Started-Header-content-full-width {
  max-width: 1200px;
  text-align: left;
  color: #fff;
  justify-content: center;
}

.Get-Started-Header h2 {
  font-size: 1.6em;
  line-height: 1.1em;
  margin-bottom: 1em;
  margin-top: 0;
  font-family: "Outfit";
  font-weight: 500;
}

/* MEDIA QUERIES */

@media screen and (max-width: 1150px){
  li.Get-Started-Step-Indicator, li.Get-Started-Step-Final-Step {
    font-size: 35px;
  }
  .Get-Started-Header h2 {
    font-size: 1.4rem;
  }
  .Get-Started-Account-Details-Container input, .Get-Started-Account-Details-Container button {
    font-size: 20px;
  }
}

@media screen and (max-width: 980px){

}


@media screen and (max-width: 880px){
  .Get-Started-Account-Details-Container .Get-Started-Account-Detail .Error-Message {
    font-size: 18px;
  } 
}

@media screen and (max-width: 650px){
  .Get-Started-Account-Details-Container .Get-Started-Account-Detail .Error-Message {
    font-size: 16px;
  }
  li.Get-Started-Step-Indicator, li.Get-Started-Step-Final-Step {
    font-size: 28px;
  }
  .Get-Started-Header h2 {
    font-size: 1.2rem;
  }
  .Get-Started-Header-Main-Section {
    padding: 50px 40px;
  }
  .Get-Started-Account-Details-Container input, .Get-Started-Account-Details-Container button{
    font-size: 17px;
  }
}

@media screen and (max-width: 475px){
  .Get-Started-Header {
    flex: 100% 0 0;
  }
  .Spotlight-Block {
    flex: 100% 0 0;
    min-height: 250px;
  }
  .Spotlight {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}