.Page {
  display: flex;
  flex: 100% 0 0;
  justify-content: center;
  background: #fff;
  padding: 70px 0;
  box-shadow: 2px 19px 50px #b5b5b5 inset;
  background: #ddd;
}


.Tools-page .Page-content {
  display: flex;
  width: 1200px;
  margin-top: -90px;
  z-index: 2;
  flex-wrap: nowrap;
  border-radius: 20px;
}

.Page-two-third {
  display: flex;
  width: 70%;
  flex: 70% 0 0 ;
  z-index: 2;
  flex-wrap: wrap;
  border: 0;
  border-radius: 20px;
  padding: 10px;
}

.nowrap {
  flex-wrap: nowrap;
}

.Page-one-third {
  display: flex;
  width: 33.3%;
  flex: 33.3% 0 0 ;
  z-index: 2;
  flex-wrap: wrap;
  border: 0;
  border-radius: 20px;
  padding: 10px;
}


.Page-Header-content-full-width:nth-child(1) .Page-one-third {
  margin-top: -90px;
}

.Page-Header-content-full-width:nth-child(1) .Page-one-fourth {
  margin-top: -90px;
}

.Page-one-third .badge,
.Page-one-fourth .badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #671c91;
  padding: 4px 17px;
  border-radius: 32px;
  font-weight: 700;
  color: #FFF;
  font-size: 14px !important;
  letter-spacing: 1px;
}

.Tool-filter-sidebar {
  display: flex;
  width: 30%;
  flex: 30% 0 0 ;
  z-index: 2;
  flex-wrap: wrap;
  border: 0;
  align-content: flex-start;
  height: fit-content;
}

.Tool-filter-sidebar h2 {
  display: flex;
  background: #000;
  margin: 0;
  line-height: 1em;
  color: #FFF;
  padding: 10px 30px;
  font-family: "Outfit";
  font-weight: 700;
  width: 100%;
  border-radius: 10px 10px 0 0;
  align-content: flex-start;
}

.Tool-filter {
  width: 100%;
  display: flex;
  flex: 100% 0 0;
  flex-wrap: wrap;
  align-content: flex-start;
}

.Tool-filter ul {
  display: flex;
  flex: 100% 0 0 ;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.Tool-filter ul li {
  display: flex;
  font-size: 21px;
  font-family: "Outfit";
  flex: 100% 0 0;
  margin: 0;
  width: 100%;
}

.Tool-filter ul li a {
  color: #333;
  text-decoration: unset;
  border-bottom: 1px solid #EEE;
  padding: 2px 10px;
  margin-bottom: 4px;
  font-size: 16px;
}

.Page-one-third.Tool a,
.Page-one-fourth a  {
  background: #5a7d19;
  color: #FFF;
  width: 100%;
  padding: 10px 10px;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  font-size: 20px;
}

.Page-one-third h2,
.Page-one-fourth h2 {
  color: #000;
}

.Page-one-third h3,
.Page-one-fourth h3 {
  color: forestgreen;
  font-family: "Outfit";
  font-weight: 600;
  font-size: 17px;
  text-align: left;
  line-height: 1em;
  margin: 0;
}

.Page-one-third p,
.Page-one-fourth p  {
  color: #000;
  font-size: 16px;
  line-height: 1.2em;
}

.Tools-page .Page-content-inner p,
.Tools-page .Page-content-inner ul li {
  font-size: 16px;
  font-family: "Outfit";
  line-height: 1.3em;
  color: #111;
  font-weight: 400;
}

.Page-one-third img,
.Page-one-fourth img {
  width: 100%;
}

.Page-one-third .Page-content-inner,
.Page-one-fourth .Page-content-inner  {
  background: #FFF;
  border: 2px solid #bbb;
  padding: 65px 25px;
  border-bottom: 0;
  position: relative;
}

.Tool-filter-sidebar .Page-content-inner {
  padding: 25px;
  border-radius: 0 0 10px 10px;
  background-color: #FFF;
  border: 2px solid #CCC;
  border-top: 0
}

.Tools-page .Page-content-inner h2 {
  font-size: 21px;
  margin-bottom: 7px;
}


.Page-content-inner {
  display: flex;
  flex-wrap: wrap;
  padding: 50px;
}

.Page-content-inner.no-wrap {
  flex-wrap: nowrap;
}


.Page-content-inner h2, 
.Page-content-inner p {
  text-align: left;
}


.Page-content-inner h2 {
  font-family: "Outfit";
  font-size: 28px;
  margin: 0;
  line-height: 1em;
  margin-bottom: 0;
}

.Page-content-inner p {
  font-size: 16px;
  line-height: 1.4em;
  margin: 10px 0;
}

.Tools-page .Page-Header-content-full-width {
  display: flex;
}

.Tools-page .Page {
  flex-wrap: nowrap;
}


/* PAGE HEADER MEDIA QUERIES */

@media screen and (max-width: 1220px){

}

@media screen and (max-width: 1150px){

}

@media screen and (max-width: 980px){

}


@media screen and (max-width: 880px){

}

@media screen and (max-width: 750px){

}

@media screen and (max-width: 320px){

}