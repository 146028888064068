    /* Services section */
    .Services {
        display: flex;
        flex: 100% 0 0;
        justify-content: center;
        background: #ddd;
        padding: 50px 0;
        border-bottom: 1px solid #ccc;
        box-shadow: 2px 19px 50px #b5b5b5 inset;
        margin-top: -10px;
        border-top: 2px solid #bbb;
        position: relative;
        z-index: 3;
    }
    .Services-content {
        display: flex;
        width: 1200px;
    }
    .Services-review {
        display: flex;
        flex: 30% 0;
        flex-wrap: wrap;
        flex-direction: column;
        text-align: left;
    }
    .Services-review-content {
        padding: 0 25px;
    }
    .Services-review-content p {
        font-size: 17px;
    }
    .Services-review h2 {
        font-size: 30px;
        font-family: "Outfit";
        margin-bottom: 0.3em;
        text-align: left;
        line-height: 1.1em;
        font-weight: 400;
    }
    .Services-review h2 span {
        width: 100%;
        display: flex;
        color: #651c8e;
        font-size: 35px;
        font-weight: 700;
    }
    .Services-grid {
        display: flex;
        flex: 70% 0 0;
        flex-wrap: wrap;
        margin-top: -80px;
    }

    .Service {
        display: flex;
        flex: 33.3% 0 0;
        flex-wrap: wrap;
        text-align: left;
        position: relative;
    }

    .Service-most-popular {
        margin-top: -25px;
    }

    .Service-most-popular-tag {
        display: inline;
        position: absolute;
        top: 10px;
        right: 10px;
        background: #f4df2f;
        color: #000;
        padding: 3px 18px;
        border-radius: 20px;
        font-family: "Outfit";
        font-size: 14px;
    }

    .Service h3 {
        font-size: 1.85em;
        font-family: "Outfit";
        font-weight: 800;
        margin-bottom: 0rem;
        text-align: left;
        margin-top: 0;
        line-height: 1em;
    }

    .Services-review-content p {
        font-size: 19px;
        font-family: "Outfit";
    }

    .Service .Service-content {
        background: #FFF;
        margin: 5px 3px;
        padding: 25px 20px;
        border: 1px solid #bbb;
        border-radius: 20px 20px 0 0;
        box-shadow: -1px -1px 0px #bbb;
        border-bottom: 0;
        margin-bottom: 0;
    }

    .Service .Service-content span.price {
        font-size: 1.3em;
        display: flex;
        flex: 100% 0 0;
        font-family: "Outfit";
        color: rgb(87, 4, 134);
        font-weight: 800;
    }

    .Service .Service-content span.price-terms {
        font-family: "Outfit";
    }

    .Service .price-btn {
        background: #333;
        color: #FFF;
        border: 0;
        margin-top: 0;
        border: 1px solid #bbb;
        padding: 10px 25px;
        border-radius: 0 0 20px 20px;
        font-family: "Outfit";
        font-size: 18px;
        font-weight: 500;
        width: 100%;
        margin: 0 3px;
        cursor: pointer;
        display: flex;
        text-decoration: unset;
        justify-content: center;
        align-items: center;
    }

    .Service .price-btn:hover {
        opacity: 0.8;
    }

    .Service .price-btn.active-plan {
        background: forestgreen;
    }

    .Service .price-btn.active-plan i {
        margin-right: 5px;
    }

    .Service h5 {
        font-size: 1.05em;
        font-family: "Outfit";
        font-weight: 500;
        margin-top: 0.5em;
        color: #444;
        line-height: 1.2em;
        margin-bottom: 0.5em;
    }
    
    .Service p {
        font-size: 1.1em;
        line-height: 1.1em;
        margin-bottom: 2rem;
    }
    
    .Service-includes {
        list-style: none;
        padding: 0;
    }

    .Service-includes li {
        font-family: "Outfit";
        border-bottom: 1px solid #eee;
        padding: 6px 5px;
        font-size: 15px;
    }

    .Service-includes li.not-included-item {
        color: #999;
        text-decoration: line-through;
    }

    .Service-includes li.not-included-item i {
        color: #999;
    }

    .Service-includes li.highlight-item {
        background: #ece13c !important;
    }

    .Service-includes li.highlight-item i {
        color: #000;
        font-weight: 700;
    }

    .Service-includes li i {
        color: #5a7d19;
    }

    
    .Service-learn-more {
    display: inline-block;
    background-color: #0054ff;
    color: #fff;
    border: none;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    }
    
    .Service-learn-more:hover {
        background-color: #003fcb;
    }


    @media screen and (max-width: 1150px){

    }
    
    @media screen and (max-width: 980px){

    }
    
    
    @media screen and (max-width: 880px){
        .Services-content {
            flex-wrap: wrap;
        }
        .Services-review {
            flex: 100% 0 0;
        }
        .Services-grid {
            flex: 100% 0 0;
            margin-top: 25px;
            margin-left: 20px;
            margin-right: 20px;
        }
        /* SService pricing item */
        .Service h3 {
            font-size: 1.2em
        }
        .Service .Service-content span.price {
            font-size: 1.1em;
        }
        .Service-includes li {
            font-size: 13px;
        }
    }
    
    @media screen and (max-width: 650px){
        .Service .Service-content {
            padding: 20px 15px;
        }
    }
    
    @media screen and (max-width: 520px){
        .Service {
            flex: 100% 0 0;
        }
        .Service .Service-content {
            width: 100%;
        }
        .Service-most-popular {
            margin-top: 0;
        }
        .Service-includes li {
            font-size: 14px
        }
    }