.Checkout-Pagete {
  display: flex;
  flex: 100% 0 0;
  justify-content: center;
  background: #fff;
  padding: 70px 0;
  box-shadow: 2px 19px 50px #b5b5b5 inset;
  background: #ddd;
}

.StripeElement { padding: 25px 0 25px 2% !important; } 

.Checkout-Content {
  display: flex;
  width: 1200px;
  background: #FFF;
  margin-top: -90px;
  z-index: 2;
  flex-wrap: wrap;
  border: 7px solid #eee;
  border-radius: 20px;
}


ul.Checkout-Step-List {
  list-style: none;
  padding: 0;
  width: 100%;
}

li.Checkout-Step-Indicator {
  font-family: "Outfit";
  font-size: 40px;
  font-style: italic;
  display: inline;
}

li.Checkout-Step-Final-Step {
  font-family: "Outfit";
  font-size: 40px;
  font-style: italic;
  display: inline;
  margin-left: 10px;
  opacity: 0.3;
}

.Checkout-Price-Container {
  background: #5a7d19;
  padding: 40px 40px;
  border-radius: 25px 25px;
  min-height: 200px;
  margin-top: 5px;
}

.Checkout-Price-Container form {
  flex: 100% 0 0;
  flex-wrap: wrap;
}

.Checkout-Price-Container .Checkout-Payment-Detail {
  float: left;
  width: 50%;
  font-family: "Outfit";
  margin-bottom: 10px;
  text-align: center;
}

.Checkout-Price-Container .Checkout-Payment-Detail.Expiry-Month, 
.Checkout-Price-Container .Checkout-Payment-Detail.Expiry-Year {
  flex: 25% 0 0;
}

.Checkout-Price-Container .Checkout-Payment-Button {
  color: #000;
  font-family: "Outfit";
  font-size: 20px;
  padding: 10px 40px;
  margin: 0; 
  background-color: #ece13c; 
  font-weight: 500;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  border-radius: 0 0 20px 20px;
}

.Checkout-Price-Container .Checkout-Payment-Button:hover {
  background-color: #ece13c; 
  opacity: 0.8;
}

.Checkout-Cart-Total {
  background: #e4ffb4;
  padding: 20px 25px;
  border-radius: 25px 25px;
  color: #000;
  font-family: "Outfit";
  font-size: 21px;
  flex: 100% 0;
  margin-bottom: 0;
}

.Checkout-Cart-Total h3 {
  margin: 0;
  font-family: "Outfit";
  font-size: 20px;
  display: inline-flex;
  font-weight: 500;
  padding-left: 5px;
}

.Checkout-Price-Container .Checkout-Payment-Detail input {
  padding: 10px 0 10px 0;
  font-size: 18px;
  color: #000;
  font-family: "Outfit";
  text-align: left;
  width: 98%;
  text-indent: 15px;
}

.StripeElement {
  display: flow-root;
  background: #fff;
  width: 98%;
  height: 100%;
  padding: 10px 0;
  padding-left: 2%;
  border-radius: 10px;
}

.Checkout-Price-Container p {
  margin: 0;
  margin-bottom: 10px;
  font-size: 20px;
}

.Checkout-Price-Container form label {
  display: flex;
  flex: 100% 0 0;

}

.Checkout-Content-inner {
  padding: 50px;
}


.Checkout-Content-inner h2, 
.Checkout-Content-inner p {
  text-align: left;
}


.Checkout-Content-inner h2 {
  font-family: "Outfit";
  font-size: 35px;
  margin: 0;
}

.Checkout-Content-inner p {
  font-size: 18px;
  line-height: 1.4em;
}

ul.Checkout-Step-List {
  list-style: none;
  padding: 0;
}

li.Checkout-Step-Indicator {
  font-family: "Outfit";
  font-size: 40px;
  font-style: italic;
  display: inline;
}

li.Checkout-Step-Final-Step {
  font-family: "Outfit";
  font-size: 40px;
  font-style: italic;
  display: inline;
  margin-left: 10px;
  opacity: 0.3;
}

.Checkout-Input {
  padding: 20px 50px 20px 25px;
  font-family: "Outfit";
  font-size: 21px;
  border: 0;
  flex: 70% 0 0 !important;
  margin: 0;
  border-radius: 0 5px 5px 0;
}

.Next-Step button {
  background: #333;
  color: #fff;
  cursor: pointer;
}

.Next-Step button i {
  margin-left: 2px;
}

.Checkout-Prefix-Select {
  padding: 20px 20px 20px 20px;
  font-family: "Outfit";
  font-size: 21px;
  background: #e4ffb4;
  border: 0;
  border-radius: 10px 0 0 10px;
  flex: 30% 0 0 !important;


}

.Checkout-Submit {
  padding: 20px 20px 20px 20px;
  font-family: "Outfit";
  color: #FFF;
  font-size: 23px;
  border: 0;
  background: #000;
  border-radius: 0 10px 10px 0;
}

.Checkout-Account-Details-Container {
  display: flex;
  flex: 100% 0;
}

.Checkout-Account-Details-Container .Checkout-Account-Detail {
  flex: 25% 0 0;

}

.Checkout-Account-Details-Container input, .Checkout-Account-Details-Container button {
  border: 0;
  font-family: "Outfit";
  font-size: 21px;
  margin: 5px;
  padding: 20px 15px;
}














.Checkout-Header {
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(87, 4, 134, 0.9);
}

.Checkout-Header-Main-Section {
  flex: 100% 0;
  display: flex;
  padding: 80px 0 150px;
  justify-content: center;
  background-size: cover;
  align-items: center;
}

.Checkout-Header-Main-Section-Full-Width {
  flex: 100% 0;
  display: flex;
  padding: 120px 0;
  justify-content: center;
  background-size: cover;
  align-items: center;
}


.Page-Header-Search-For-Business h2,
.Page-Header-Search-For-Business input {
  display: flex;
  flex: 100% 0 0;
}

.Checkout-Input-Container {
  display: flex;
  flex: 100% 0 0;
  margin: 5px;
}

.Checkout-Header-content {
  max-width: 1200px;
  text-align: left;
  color: #fff;
  justify-content: center;
}

.Checkout-Header-content-full-width {
  max-width: 1200px;
  width: 1200px;
  text-align: left;
  color: #fff;
  justify-content: center;
}

.Checkout-Header h2 {
  font-size: 1.8em;
  line-height: 1.1em;
  margin-top: 0;
  font-family: "Outfit";
  font-weight: 500;
  margin-bottom: 0;
}

.Checkout-Header .plans-container h2 {
  margin-bottom: 10px;
}


.checkout-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .plans-container {
    width: 50%;
    float: left;
    display: flex;
    flex-wrap: wrap;
  }

  .Checkout-Service-Option label {
    cursor: pointer;
  }

  .Checkout-Service-Option {
    display: flex;
    flex-wrap: wrap;
    cursor: pointer !important;
    flex: 100% 0;
    background: #FFF;
    border: 2px solid #ccc;
    margin-bottom: 10px;
    border-radius: 20px;
    padding: 21px 30px;
    color: #000;
    font-family: "Outfit";
    font-size: 20px;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
  }
  .Checkout-Service-Option .Service-Option-Description {
    font-size: 15px;
    color: #222;
    line-height: 1em;
  }
  .Checkout-Service-Option .price {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #a2c860;
    font-family: "Outfit";
    font-size: 14px;
    font-style: italic;
    font-weight: 900;
  }
  .Checkout-Service-Option a {
    text-decoration: unset;
    color: unset;
    cursor: unset;
  }

  .Checkout-Service-Option input {
    cursor: pointer;
    margin-right: 15px;
  }
  .Checkout-Service-Option label {
    font-weight: 900;
  }
  .Checkout-Service-Option:hover {
    background: #eee;
  }
  
  .Checkout-Form-Container {
    float: left;
    width: 50%;
    position: relative;
    overflow: hidden;
  }

  .Checkout-Form-Container p.Checkout-Cart-Total {
    font-size: 23px;
    line-height: 1em;
  }

  .Checkout-Form-Container .Checkout-Cart-Total h2 {
    font-size: 23px;
    color: #5a7d19;
    font-weight: 700;
  }


  .Checkout-Form-Container .Checkout-Cart-Description {
    font-size: 16px;
    line-height: 1em;
    margin-top:0;
  }

  .Checkout-Header-content .plans-container {
    font-size: 17px;
    margin-top: 10px;
  }

  .Checkout-Header-content .plans-container-inner {
    padding-right: 45px;
  }

  .Checkout-Header-content .plans-container p {
    font-family: "Outfit";
    font-size: 18px;
    margin: 0;
    margin-bottom: 20px;
  }

  .Checkout-Form-Container h2 {
    display: flex;
    flex: 100% 0 0;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 16px;
  }
  
  label {
    display: flex;
  }
  
  input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  button[type='submit'] {
    background-color: #007bff;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button[type='submit']:hover {
    background-color: #0069d9;
  }
  
  input:focus {
    outline: none;
    border-color: #007bff;
  }

  /* BUSINESS LISTING FORM CONTAINER */

  .Business-Listing-Form-Container {
    background: #FFF;
    border: 3px solid #EEE;
    padding: 20px 50px;
    box-sizing: border-box;
    border-radius: 20px;
  }

  .Business-Listing-Form-Container label {
    color: #000;
  }

  .Business-Listing-Form-Container input,
  .Business-Listing-Form-Container textarea {
    background: #EEE;
    border: 0;
    width: 100%;
  }


  /* MEDIA QUERIES */
  
  @media screen and (max-width: 1220px){
    .Checkout-Header-content {
      padding: 0 20px;
    }
  }

  @media screen and (max-width: 1150px){
    li.Checkout-Step-Indicator, li.Checkout-Step-Final-Step {
      font-size: 35px;
    }
    /* STRIPE CHECKOUT FORM */
    .Checkout-Price-Container {
      padding: 20px 40px;
    }
    .Checkout-Price-Container p {
      font-size: 18px;
    }
    .Checkout-Cart-Total {
      font-size: 18px;
    }
    .Checkout-Price-Container .Checkout-Payment-Button {
      font-size: 20px;
      padding: 10px 30px;
    }
  }
  
  @media screen and (max-width: 980px){

  }
  
  
  @media screen and (max-width: 880px){
    .Checkout-Form-Container h2,
    .Checkout-Price-Container p {
      text-align: center;
    }
    .Checkout-Price-Container p {
      margin-top: 20px;
      font-size: 18px;
      line-height: 1.1em;
    }
    .Checkout-Price-Container {
      padding: 30px;
    }
    .Checkout-Header-content .plans-container p {
      font-size: 16px;
    }
    .plans-container {
      width: 60%;
    }
    .Checkout-Form-Container {
      width: 40%;
    }
    .Checkout-Header h2 {
      font-size: 1.5em;
    }
    li.Checkout-Step-Indicator, li.Checkout-Step-Final-Step {
      font-size: 28px;
    }
    .Checkout-Header-content .plans-container-inner {
      padding-right: 20px;
    }
    /* STRIPE CHECKOUT FORM */
    .Checkout-Price-Container {
      padding: 40px 20px;
      min-height: 170px;
    }
    .Checkout-Price-Container p {
      text-align: left;
      margin-top: 0;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 750px){
    .Checkout-Cart-Total {
      font-size: 16px;
    }
    .Checkout-Price-Container {
      padding: 20px;
    }
    .Checkout-Price-Container .Checkout-Payment-Button {
      font-size: 20px;
      padding: 10px 22px;
      margin-top: 10px;
      font-size: 18px;
    }
    .plans-container, .Checkout-Form-Container {
      width: 100%;
    }
    .Checkout-Header-content .plans-container-inner {
      padding-right: 0;
    }
  }
  
  @media screen and (max-width: 475px){
    .Checkout-Header-Main-Section {
      padding: 0;
    }
    .Checkout-Form-Container {
      margin-top: 25px;
    }
  }
