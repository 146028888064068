.tool-item-inner-image { 
  width: 100%;
  flex: 25% 0;
  height: auto;
  background: #EEE;
  border: 2px solid #CCC;
  border-radius: 5px 0 0 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  min-height: 200px;
  box-sizing: border-box;
  object-fit: cover;
  border-radius: 10px 0 0 10px;
}

.tool-item-inner-image img {
  object-fit: cover;
  display: flex;
  height: 100%;
}

.tool-item-inner-image i {
  font-size: 40px;
}

.tool-item-inner-details-container p {
  margin: 0;
    margin-top: 0px;
    margin-bottom: 0px;
  text-align: left;
  font-family: "Outfit";
}

.tool-item-inner-details-container a {
  color: #000;
  margin-top: 5px;
  display: inline-flex;
  font-family: "Outfit";
  padding: 4px 9px;
  text-decoration: unset;
  position: absolute;
  bottom: 3px;
  right: 3px;
  font-size: 15px;
  line-height: 1em;
  align-items: flex-end;
}

.tool-item-inner-details-container {
  flex: 75% 0;
  padding: 20px 25px 50px 25px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  text-align: left;
  position: relative;
  text-transform: capitalize;
  background: #FFF;
  border-radius: 0 10px 10px 0;
  border: 2px solid #ddd;
  border-left: 0;
}

.tool-item-inner-details-container h2 {
  margin: 0;
    margin-top: 0px;
  font-size: 20px;
  font-family: "Outfit";
  text-align: left;
  line-height: 1.1em;
}

/* BusinessItem.css */

.business-item.tool-item {
  flex: 100% 0;
  padding: 0 5px 10px;
  border-bottom: 0;
  margin-bottom: 0;
  box-sizing: border-box;
  padding-right: 5px;
  }

  .business-item.tool-item img {
    width: 100%;
    border-radius: 10px 0 0 10px;
  }

  .business-item-inner {
    display: flex;
    flex-wrap: wrap;
  }

  .business-item .plan-detail {
    color: #62bb48;
    margin-top: 8px;
    color: #651c8e;
    border-bottom: 2px solid #eee;
    margin-bottom: 12px;
    padding-bottom: 12px;
    font-weight: 500;
    font-family: "Outfit";
    font-weight: 700;
  }

  .business-item .plan-detail .price {
    position: absolute;
    top: 10px;
    right: 15px;
    padding: 4px 11px;
    border-radius: 10px;
    color: #333;
    font-family: "Outfit";
    font-weight: 700;
    font-size: 16px;
    border: 3px solid #eee;
    text-transform: none;
  }

  .business-item .plan-detail.complete,
  .business-item .plan-detail.complete i,
  .business-item .plan-detail.all-plans,
  .business-item .plan-detail.all-plans i  {
    color: #651c8e;
  }

  .business-item .plan-detail.free,
  .business-item .plan-detail.free i {
    color: #62bb48;
  }

  .business-item .plan-detail i {
    color: #62bb48;
    font-size: 18px;
    margin: 0;
  }

  .business-item .tool-subtitle {
    font-size: 16px;
    line-height: 1.2em;
    text-transform: none;
  }

  .business-item-inner-details {
    flex: 60% 0 0;
    padding: 20px 25px 50px 25px;
    box-sizing: border-box;
    border: 1px solid #CCC;
    text-align: left;
    position: relative;
    text-transform: capitalize;
    background: #FFF;
    border-radius: 0 5px 5px 0;
  }

  .business-item-inner-details a {
    color: #000;
    margin-top: 5px;
    display: inline-flex;
    font-family: "Outfit";
    padding: 4px 9px;
    text-decoration: unset;
    position: absolute;
    bottom: 3px;
    right: 3px;
    font-size: 15px;
    line-height: 1em;
    align-items: flex-end;
  }

  .business-item-inner-details a:hover { 
    color: #651c8e 
  } 

  .business-item-inner-details a i {
    font-size: 12px;
    margin-left: 4px;
  }

  .business-item-inner-image {
    width: 100%;
    flex: 40% 0;
    height: auto;
    background: #EEE;
    border: 2px solid #CCC;
    border-radius: 5px 0 0 5px;
    justify-content: center;
    display: flex;
    align-items: center;
    min-height: 200px;
    box-sizing: border-box;
  }
  
  .business-item-inner-image img {
    max-height: 200px;
    object-fit: cover;
    align-content: center;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  .business-item-inner-image i {
    font-size: 40px;
  }

  .business-item-inner-details h2 {
    margin: 0;
    font-size: 20px;
    font-family: "Outfit";
    text-align: left;
    line-height: 1.1em;
  }

  .business-item-inner-details p {
    margin: 0;
    text-align: left;
    font-family: "Outfit";
  }

  .business-item-inner-details p i {
    font-size: 14px;
    margin-right: 2px;
    color: #000;
  }
  
  .business-item img {

  }

  
  .business-item h2 {
    margin-top: 10px;
    font-size: 1.5em;
  }
  
  .business-item p {
    margin-top: 5px;
    color: #555;
    border-bottom: 1px solid #fafafa;
    margin-bottom: 5px;
    padding-bottom: 5px;
  }

  /* Loading */

.business-item {
  animation: fadeInAnimation 0.5s;
}



  @keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
  }

  .mobile-plan-details {
    display: none;
  }

  @media screen and (max-width: 750px){
    .business-item.tool-item {
      padding-left: 0;
      padding-right: 0;
    }
    .business-item.tool-item {
      padding-right: 0;
    }
  }

  @media screen and (max-width: 650px){
    .tool-item-inner-details-container p,
    .tool-item-inner-details-container a {
      display: none;
    }
    .tool-item-inner-details-container .plan-detail {
      display: flex;
      margin: 0;
      margin-top: 3px;
      padding: 0;
      font-size: 14px;
      border: 0;
    }
    .tool-item-inner-details-container .plan-detail i {
      margin-right: 3px;
      font-size: 14px;
    }
    .business-item.tool-item img {
      object-fit: contain;
      object-position: top;
      border-radius: 10px 0 0 0;
      background: #DDD;
      min-height: 85px;
    }
    .tool-item-inner-image {
      min-height: unset;
    }
    .mobile-plan-details {
      display: flex;
      flex-wrap: wrap;
      background: #FFF;
      border-radius: 0 0 10px 10px;
      border-top: 0;
      padding: 15px;
      border: 2px solid #BBB;
      border-top: 0;
      box-sizing: border-box;
      text-align: left;
      font-family: "Outfit";
      justify-content: flex-end;
    }
    .mobile-plan-details .tool-subtitle {
      text-align: left;
      border: 0;
    }
    .tool-item-inner-details-container {
      align-items: center;
      background: #FAFAFA;
      align-content: center;
      display: flex;
      padding: 0;
      text-align: center;
      justify-content: flex-start;
      padding-left: 25px;
      padding-right: 25px;
      box-sizing: border-box;
      border-radius: 0 10px 0 0;
      border-top: 2px solid #bbb;
      border-right: 2px solid #bbb;
      border-bottom-width: 2px;
    }
    .mobile-plan-details a i {
      font-size: 12px;
      margin-left: 3px;
    }
    .mobile-plan-details a {
      color: #000;
      text-decoration: unset;
      font-family: "Outfit";
      padding: 4px 18px;
      border-radius: 10px;
      color:#FFF;
      font-size: 15px;
      background: #6fb75a;
      display: flex;
      align-items: center;
    }
    .tool-item-inner-image {
      border: 0;
      border-radius: 10px 0 0 0;
      border-top: 2px solid #BBB;
      border-left: 2px solid #BBB;
    }
    .tool-item-inner-image img {
      border-radius: 10px 0 0 0;
    }
    .business-item .plan-detail .price {
      font-size: 12px;
      padding: 3px 9px;
      top: 3px;
      right: 3px;
      border-width: 1px;
      color: #444;
    }
    .business-item .tool-subtitle {
      font-size: 14px;
    }
    .business-item.tool-item {
      padding: 0 0 10px;
      border-bottom: 2px dotted #ccc;
      margin-bottom: 10px;
      }
  }