.Login-Pagete {
  display: flex;
  flex: 100% 0 0;
  justify-content: center;
  background: #fff;
  padding: 70px 0;
  box-shadow: 2px 19px 50px #b5b5b5 inset;
  background: #ddd;
}

.Login-Content {
  display: flex;
  width: 1200px;
  background: #FFF;
  margin-top: -90px;
  z-index: 2;
  flex-wrap: wrap;
  border: 7px solid #eee;
  border-radius: 20px;
}

.Login-Content-inner {
  padding: 50px;
}


.Login-Content-inner h2, 
.Login-Content-inner p {
  text-align: left;
}


.Login-Content-inner h2 {
  font-family: "Outfit";
  font-size: 35px;
  margin: 0;
}

.Login-Content-inner p {
  font-size: 18px;
  line-height: 1.4em;
}

ul.Login-Step-List {
  list-style: none;
  padding: 0;
}

li.Login-Step-Indicator {
  font-family: "Outfit";
  font-size: 40px;
  font-style: italic;
  display: inline;
}

li.Login-Step-Final-Step {
  font-family: "Outfit";
  font-size: 30px;
  font-style: italic;
  display: inline;
  margin-left: 10px;
  opacity: 0.3;
}

.Login-Input {
  padding: 20px 50px 20px 25px;
  font-family: "Outfit";
  font-size: 21px;
  border: 0;
  flex: 70% 0 0 !important;
  margin: 0;
  border-radius: 0 5px 5px 0;
}

.Next-Step button {
  background: #333;
  color: #fff;
  cursor: pointer;
  width: calc(100% - 10px);
}

.Next-Step button i {
  margin-left: 2px;
}

.Login-Prefix-Select {
  padding: 20px 20px 20px 20px;
  font-family: "Outfit";
  font-size: 21px;
  background: #e4ffb4;
  border: 0;
  border-radius: 10px 0 0 10px;
  flex: 30% 0 0 !important;


}

.Login-Submit {
  padding: 20px 20px 20px 20px;
  font-family: "Outfit";
  color: #FFF;
  font-size: 23px;
  border: 0;
  background: #000;
  border-radius: 0 10px 10px 0;
}

.Login-Account-Details-Container {
  display: flex;
  flex: 100% 0;
  flex-wrap: wrap;
}

.Login-Account-Details-Container .Login-Account-Detail {
  flex: 100% 0 0;
  flex-shrink: 0;

}

.Login-Account-Details-Container input {
  border: 0;
  font-family: "Outfit";
  font-size: 21px;
  margin: 5px;
  padding: 20px 15px;
  width: calc(100% - 40px);
}

.Login-Account-Signin-Button button {
  border: 0;
  font-family: "Outfit";
  font-size: 21px;
  margin: 5px;
  padding: 20px 15px; 
  background: #333;
  color: #fff;
  cursor: pointer;
  width: calc(100% - 10px)
}














.Login-Header {
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(87, 4, 134, 0.9);
  flex: 60% 0 0;
}

.Login-Header-Main-Section {
  flex: 100% 0;
  display: flex;
  padding: 80px 60px 150px;
  justify-content: center;
  background-size: cover;
  align-items: center;
}

.Login-Header-Main-Section-Full-Width {
  flex: 100% 0;
  display: flex;
  padding: 120px 0;
  justify-content: center;
  background-size: cover;
  align-items: center;
}


.Page-Header-Search-For-Business h2,
.Page-Header-Search-For-Business input {
  display: flex;
  flex: 100% 0 0;
}

.Login-Input-Container {
  display: flex;
  flex: 100% 0 0;
  margin: 5px;
}

.Login-Header-content {
  max-width: 800px;
  text-align: left;
  color: #fff;
  justify-content: center;
}

.Login-Header-content .Error-Message {
  width: calc(100% - 40px);
  border-radius: 10px;
  padding: 10px 15px;
  font-size: 15px;
  font-weight: 500;
  background: #ffa6a6;
  border: 2px solid pink;
  line-height: 1em;
  color: #000;
  display: flex;
  margin-bottom: 0;
  margin: 4px 4px 2px 4px;
}

.Login-Header-content-full-width {
  max-width: 1200px;
  text-align: left;
  color: #fff;
  justify-content: center;
}

.Login-Header h2 {
  font-size: 1.6em;
  line-height: 1.1em;
  margin-bottom: 1em;
  margin-top: 0;
  font-family: "Outfit";
  font-weight: 500;
}

/* MEDIA QUERIES */

@media screen and (max-width: 1150px){
  li.Login-Step-Indicator {
    font-size: 35px;
  }
  .Login-Header h2 {
    font-size: 1.4rem;
  }
  .Login-Account-Details-Container input, .Login-Account-Signin-Button button {
    font-size: 20px;
  }
}

@media screen and (max-width: 980px){

}


@media screen and (max-width: 880px){

}

@media screen and (max-width: 650px){
  li.Login-Step-Indicator {
    font-size: 28px;
  }
  .Login-Header h2 {
    font-size: 1.2rem;
  }
  .Login-Header-Main-Section {
    padding: 50px 40px;
  }
  .Login-Account-Details-Container input, .Login-Account-Signin-Button button {
    font-size: 17px;
  }
}

@media screen and (max-width: 475px){
  .Login-Header {
    flex: 100% 0 0;
  }
}