.Page {
  display: flex;
  flex: 100% 0 0;
  justify-content: center;
  background: #fff;
  padding: 70px 0;
  box-shadow: 2px 19px 50px #b5b5b5 inset;
  background: #ddd;
  position: relative;
  z-index: 3;
}
.Page-content {
  display: flex;
  width: 1200px;
  margin-top: -90px;
  z-index: 2;
  flex-wrap: wrap;
  border-radius: 20px;
}


.Page-content-inner {
  display: flex;
  flex-wrap: wrap;
  padding: 50px;
}

.Page-content-inner.no-wrap {
  flex-wrap: nowrap;
}


.Page-content-inner h2, 
.Page-content-inner p {
  text-align: left;
}


.Page-content-inner h2 {
  font-family: "Outfit";
  font-size: 35px;
  margin: 0;
}

.Page-content-inner p {
  font-size: 18px;
  line-height: 1.4em;
  margin: 10px 0;
}

.About-page .Page-content-inner {
  width: 100%;
  background: #FFF;
  border: 1px solid #CCC;
  border-radius: 10px;
}



.Page-content-inner .About-info {
  flex: 100% 0;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.Page-content-inner .About-info h2:first-child {
  margin-top: 0;
}

.Page-content-inner .About-info h2 {
  font-size: 27px;
  margin-top: 35px;
}

.Page-content-inner .Referral-info ul {
  text-align: left;
  padding-left: 15px;
}

.Referral-page .Page-content-inner {
  background: #FFF;
  border: 1px solid #CCC;
}

/* PAGE HEADER MEDIA QUERIES */

@media screen and (max-width: 1220px){

}

@media screen and (max-width: 1150px){

}

@media screen and (max-width: 980px){

}


@media screen and (max-width: 880px){

}

@media screen and (max-width: 750px){

}

@media screen and (max-width: 320px){

}