h2.affiliate-registration-heading {
  display: flex;
}

h2.affiliate-registration-heading i { 
  flex: 18% 0 0;
  background: #333;
  color: #FFF;
  display: inline-block;
  flex-wrap: wrap;
  padding: 17px 14px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

h2.affiliate-registration-heading span {
  padding-left: 10px;
  font-family: "outfit";
  font-weight: 700;
}

.Page-content-inner .Referral-info {
  flex: 70% 0;
  flex-wrap: wrap;
  border-right: 3px solid #eee;
  box-sizing: border-box;
  padding-right: 20px;
}

.Page-content-inner .Referral-info ul {
  text-align: left;
  padding-left: 15px;
}

.Referral-page .Page-content-inner {
  background: #FFF;
  border: 1px solid #CCC;
}

.Page-content-inner .Referral-program-form {
  flex: 30% 0;
  flex-wrap: wrap;
}

.Page-content-inner h2, 
.Page-content-inner p {
  text-align: left;
}

.Page-content-inner h2 {
  font-family: "Outfit";
  font-size: 35px;
  margin: 0;
  margin-bottom: 15px;
}

.Page-content-inner p,
.Page-content-inner ul li {
  font-size: 18px;
  line-height: 1.4em;
  color: #333;
  font-family: "Outfit";
}

.Page-content-inner strong {
  color: #000;
}

.Referral-registration-form input {
  background: #EEE;
  font-family: "Outfit";
  font-size: 18px;
  width: 100%;
  margin-bottom: 10px;
  flex: 100% 0;
}

.Referral-registration-form .Field-Container span {
  color: red;
  text-align: left;
  margin-bottom: 5px;
}

.Referral-registration-form-inner {
  display: flex;
  flex: 100% 0;
  flex-wrap: wrap;
  padding-left: 35px;
}

.Referral-registration-form-inner .Field-Container {
  display: flex;
  flex: 100% 0;
  flex-wrap: wrap;
}

.Referral-registration-form button {
  width: 100%;
  font-size: 1.3em;
  margin-bottom: 10px;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-image: linear-gradient(rgba(87, 4, 134, 0.9), rgba(98, 0, 134, 0.88));
}







.referral-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-family: "Outfit";
}

.referral-table th,
.referral-table td {
  border: 1px solid #ddd;
  padding: 6px 12px;
  text-align: center;
}

.referral-table th {
  background-color: #000;
  color: #FFF;
  border: 0;
}

.referral-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.referral-table tr:hover {
  background-color: #eaf3ff;
}

.referral-table td {
  font-size: 1rem;
  color: #555;
}

