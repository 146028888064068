    /* How it works */
    .How-it-works {
        display: flex;
        flex: 100% 0 0;
        justify-content: center;
        background: linear-gradient(65deg, #fff 70%, #eee 10%);
        padding: 70px 0 120px;
        box-shadow: 2px 2px 50px #eee inset;
    }
    .How-it-works-content {
        display: flex;
        width: 1200px;
    }
    .How-it-works-explaination {
        display: flex;
        flex: 50% 0;
        flex-wrap: wrap;
        flex-direction: column;
        padding: 0 25px;
    }
    .How-it-works-explaination-content iframe,
    .How-it-works-explaination-content img { 
        flex: 100% 0 0; 
        display: flex; 
        width: 100%;    
        border: 0;
        border-radius: 20px;
        box-shadow: -10px -10px 0px #e4ffb4;
    }
    
    .How-it-works-explaination h2,
    .How-it-works-explaination h3, 
    .How-it-works-explaination p {
        text-align: left;
    }
    .How-it-works-explaination h2 {
        font-family: "Outfit";
        font-size: 45px;
        margin-bottom: 0;
    }
    .How-it-works-explaination h3 {
        font-family: "Outfit";
        color: rgba(87, 4, 134, 0.9);
        font-size: 23px;
        font-weight: 500;
        margin-top: 0.3em;
        margin-bottom: 0;
    }
    .How-it-works-explaination p {
        font-size: 19px;
        font-family: "Outfit";
    }
    .How-it-works .divider {
        height: 3px;
        background: #a4ce59;
        width: 15%;
        margin: 1.2em 0;
      }

      /* MEDIA QUERIES */

      @media screen and (max-width: 1150px){

      }
      
      @media screen and (max-width: 980px){
    
      }
      
      
      @media screen and (max-width: 880px){
        .How-it-works-explaination h2 {
            font-size: 35px;
            line-height: 1.1em;
        }

        .How-it-works-explaination h3{
            font-size: 1.2rem;
        }
        .How-it-works-explaination p {
            font-size: 16px;
        }
        .How-it-works-explaination-content iframe {
            height: 300px;
        }

      }
      
      @media screen and (max-width: 650px){
      }
      
      @media screen and (max-width: 570px){
        .How-it-works-content {
            flex-wrap: wrap;
        }
        .How-it-works-explaination {
            flex: 100% 0 0;
        }
        .How-it-works-explaination-content iframe {
            margin-top: 20px;
        }
        .How-it-works {
            padding: 20px 0;
        }
      }