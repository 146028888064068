.Directory-Page .Page {
  display: flex;
  flex: 100% 0 0;
  justify-content: center;
  background: #fff;
  padding: 70px 0;
  box-shadow: 2px 19px 50px #b5b5b5 inset;
  background: #ddd;
}

.Page-content {
  display: flex;
  width: 1200px;
  background: transparent;
  z-index: 2;
  flex-wrap: wrap;
  border: 0;
}

.Page-content-70 {
  max-width: 70%;
  text-align: left;
  color: #fff;
  justify-content: left;
  flex: 70% 0;
  display: flex;
  flex-wrap: wrap;
  color: #000;
}

.Page-content-70 .Page-content-inner {
  background: #FFF;
  margin: 0 30px 0 0;
  align-content: flex-start;
  width: 100%;
  border-radius: 10px;
}

.Page-content-30 {
  max-width: 30%;
  text-align: left;
  color: #fff;
  justify-content: left;
  flex: 30% 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: -400px;
  background: #FFF;
  align-content: flex-start;
  height: fit-content;
  border-radius: 10px

}

.Page-content-30 h3, 
.Page-content-30 ul li {
  color: #000;
}

.Tool-detail-page {
  overflow-x: hidden
}

.Tool-detail-page .Page-content {
  padding: 0 20px;
  box-sizing: border-box;
}

.Page-content-30 h3 {
  font-family: "Outfit";
  margin-bottom: 10px;
  margin-top: 0;
}

.Page-content-inner .plan-detail {
  line-height: 1em;
}

.Tool-detail-page .Page-content-30 h3 {
  color: #610587;
  margin: 0;
  width: 100%;
}

.Page-content-30 h4 {
  font-family: "Outfit";
  color: #444;
  font-size: 17px;
  line-height: 1em;
  font-style: italic;
  font-weight: 500;
  margin-bottom: 0;
  border-top: 1px solid #DDD;
  padding-top: 15px;
  margin-top: 0;
}



.Page-content-30 ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.Page-content-30 ul li {
  font-size: 18px;
  line-height: 1.4em;
  border-bottom: 1px solid #ddd;
  padding: 9px 0;
  font-family: "Outfit";
  font-weight: 500;
}

.Page-content-30 ul li i {
  color: forestgreen;
}

.Page-content-30 a {
  background: #000;
  width: 100%;
  color: #FFF;
  font-size: 20px;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  text-decoration: none;
  padding: 8px 0;
  width: 100%;
}

.Page-content-30 a i {
  margin-right: 10px;
}

.Page-content-30 img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.Page-content-30 .Page-content-inner {
  display: inline-table;
  width: 100%;
  border-radius: 0 0 10px 10px;
}

.Page-content-inner {
  display: flex;
  flex-wrap: wrap;
  padding: 50px;
}

.Page-content-inner.no-wrap {
  flex-wrap: nowrap;
}


.Page-content-inner h2, 
.Page-content-inner p {
  text-align: left;
}


.Page-content-inner h2 {
  font-family: "Outfit";
  font-size: 35px;
  margin: 0;
}

.Page-content-inner p {
  font-size: 18px;
  line-height: 1.4em;
  margin: 10px 0;
}

.tool-description {
  width: 100%;
  box-sizing: border-box;
}

.tool-description a {
  text-decoration: unset;
}

.tool-description a:hover {
  opacity: 0.7;
}

.tool-description img {
  width: 100%;
}

.Page-content-inner .tool-description h2 {
  border-top: 2px solid #DDD;
  padding-top: 25px;
  margin-top: 25px;
}

.tool-description h2:first-child {
  border: 0;
  padding: 0;
  margin: 0;
  font-size: 30px;
}

.step-1,
.step-2,
.step-3,
.step-4,
.step-5,
.step-6,
.step-7,
.step-8,
.step-9,
.step-10 {
  background: #FAFAFA;
  padding: 20px;
  box-sizing: border-box;
  border: 2px solid #DDD;
  margin-top: 15px;
  border-radius: 10px;
}


.step-1 img,
.step-2 img,
.step-3 img,
.step-4 img,
.step-5 img,
.step-6 img,
.step-7 img,
.step-8 img,
.step-9 img,
.step-10 img {
  margin-top: 15px;
  border-radius: 15px;
}

.step-by-step-heading strong {
  font-family: "Outfit";
  font-size: 20px;
}

.step-1:first-child {
  margin-top: 0;
}

.tool-description ul {
  padding-left: 15px;
}

.tool-description ul li {
  padding-bottom: 5px;
}

.Tool-filter-sidebar {
  opacity: 0;
  border-radius: 10px;
  padding-left: 5px;
  box-sizing: border-box;
}


.Tool-filter-sidebar {
  animation: fadeInAnimation 0.5s forwards;
  animation-delay: 0.4275s;
}



  @keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
  }




.Tool-filter-sidebar .Tool-filter li,
.Tool-filter-sidebar .Tool-filter a {
  text-align: left;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  border: 0;
  line-height: 1em;
}

.Tool-filter-sidebar .Tool-filter li i {
  margin-right: 5px;
}


/* PAGE HEADER MEDIA QUERIES */

@media screen and (max-width: 1220px){

}

@media screen and (max-width: 1150px){

}

@media screen and (max-width: 980px){
  .Page-content-70 .Page-content-inner {
    padding: 40px 30px;
  }
  .Page-content-70 .Page-content-inner p, .Page-content-70 .Page-content-inner ul li {
    font-size: 16px;
  }
  .tool-description h2:first-child,
  .Page-content-inner .tool-description h2 {
    font-size: 25px;
  }
  .Page-content-30 .Page-content-inner {
    padding: 30px;
  }
}


@media screen and (max-width: 880px){

}

@media screen and (max-width: 750px){
  .Tool-filter-sidebar {
    width: 100%;
    flex: 100%;
    margin-top: 0px;
  }

  .Tool-filter-sidebar .Tool-filter li {
    width: 33.3%;
    flex: 33.3%;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    flex-grow: 0;
  }
  .Tool-filter-sidebar .Tool-filter li a {
    padding: 7px;
    box-sizing: border-box;
    background-color: #EEE;
    min-height: 80px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 2px;
    font-size: 15px;
  }
  .Business-Directory .directory-business-search-container button {
    flex: 25% 0;
  }
  .Tool-filter-sidebar .Tool-filter li a:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  .Tool-filter-sidebar .Tool-filter li i {
    margin-bottom: 3px;
  }
  .Tool-filter-sidebar .Tool-filter li i,
  .Tool-filter-sidebar .Tool-filter li a {
    width: 100%;
    justify-content: center;
    align-content: center;
  }
  /* Single page  */
  .Page-Header-content-70,
  .Page-content-30,
  .Page-content-70 {
    width: 100%;
    flex: 100%;
    max-width: 100%;
  }
  .Page-Header-content-30 {
    display: none;
  }
  .Page-content-70 .Page-content-inner {
    margin: 0;
  }
  .Tool-detail-page .Page-content-30 {
    margin: 0;
    margin-bottom: 10px;
    border-radius: 15px;
  }
  .Page-content-30 .Page-content-inner {
    border-radius: 0 15px 15px 0;
  }
  .Tool-detail-page .Page-content-30 img {
    border-radius: 15px 0 0 15px;
  }
  .Tool-detail-page .page {
    padding-bottom: 0;
  }
  .Page-content-30 {
    order: 0;
  }
  .Page-content-70 {
    order: 1;
  }
  .Page-content-30 img {
    width: 30%;
    box-sizing: border-box;
    border-radius: 0;
    object-fit: cover;
  }
  .Page-content-30 .Page-content-inner {
    width: 70%;
    box-sizing: border-box;
    background: #FAFAFA;
    border-bottom: 1px solid #DDD;
    display: flex;
    align-items: center;
    align-content: center;
  }

  .Page-content-30 img {
    width: 30%;
  }
  .Page-content-30 .Page-content-inner {
    width: 70%;
  }
  .Page-content-inner .plan-detail {
    margin-top: 0;
  }
.step-1,
.sstep-2,
.step-3,
.step-4,
.step-5,
.step-6,
.step-7,
.step-8,
.step-9,
.step-10 {
  background: transparent;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  border-top: 2px solid #eee;
  margin-top: 15px;
  padding-top: 15px;
  border-radius: 0;
}
}


@media screen and (max-width: 575px){
  .Tool-detail-page .Page-content-30 img {
    object-fit: contain;
    object-position: top;
  }
  .Page-content-30 .Page-content-inner {
    padding: 20px 30px;
  }
  .Page-content-inner p, .Page-content-inner ul li {
    font-size: 15px;
  }
  .Page-content-30 h4 {
    font-size: 15px;
  }
  .Directory-page .Page {
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .Tool-directory-page .Page {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 475px){
  .Tool-detail-page .Page-content-30 img {
    border-radius: 15px 0 0 15px;
  }
  .Page-content-inner .plan-detail {
    font-size: 14px;
  }
  .Tool-detail-page .Page-content-30 h3 {
    font-size: 15px;
  }
  .Page-content-30 h4 {
    padding-top: 7px;
    font-size: 14px;
  }
  .Page-content-30 .Page-content-inner {
    padding: 10px 30px
  }
  .tool-description h2:first-child, .Page-content-inner .tool-description h2 {
    font-size: 21px;
  }
  .Page-content-70 .Page-content-inner p, .Page-content-70 .Page-content-inner ul li {
    font-size: 15px;
  }
  .Page-content-70 .Page-content-inner {
    padding: 30px 20px;
  }
}

@media screen and (max-width: 400px){
  .Page-content-inner .plan-detail {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .Page-content-30 h4 {
    padding-top: 3px;
    border: 0;
  }
  .Page-content-30 .Page-content-inner {
    padding: 10px 20px;
  }
  .Tool-detail-page .Page-content-30 h3 {
    font-size: 14px;
  }
  .Page-Header.Business-Directory .Page-Header-content-100 {
    padding: 0;
  }
}

@media screen and (max-width: 320px){
  .Tool-detail-page .Page-content-30 img {
    object-fit: cover;
  }
}