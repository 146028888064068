/* BusinessItem.css */

.business-item {
    flex: 50% 0;
    padding: 0 5px 10px;
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
  }

  .business-item-inner {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .business-item-inner-details {
    flex: 60% 0 0;
    width: 60%;
    padding: 20px 25px 50px 25px;
    box-sizing: border-box;
    border: 1px solid #CCC;
    text-align: left;
    position: relative;
    text-transform: capitalize;
    background: #FFF;
    border-radius: 0 5px 5px 0;
  }

  .business-item-inner-details a {
    color: #000;
    margin-top: 5px;
    display: inline-flex;
    font-family: "Outfit";
    padding: 4px 9px;
    text-decoration: unset;
    position: absolute;
    bottom: 3px;
    right: 3px;
    font-size: 15px;
    line-height: 1em;
    align-items: flex-end;
  }

  .business-item-inner-details a:hover { 
    color: #651c8e 
  } 

  .business-item-inner-details a i {
    font-size: 12px;
    margin-left: 4px;
  }

  .business-item-inner-image {
    width: 40%;
    flex: 40% 0;
    height: auto;
    background: #EEE;
    border: 2px solid #CCC;
    border-radius: 5px 0 0 5px;
    justify-content: center;
    display: flex;
    align-items: center;
    min-height: 200px;
    box-sizing: border-box;
  }
  
  .business-item-inner-image img {
    max-height: 200px;
    object-fit: cover;
    align-content: center;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  .business-item-inner-image i {
    font-size: 40px;
  }

  .business-item-inner-details h2 {
    margin: 0;
    font-size: 20px;
    font-family: "Outfit";
    text-align: left;
    line-height: 1.1em;
  }

  .business-item-inner-details p {
    margin: 0;
    text-align: left;
    font-family: "Outfit";
  }

  .business-item-inner-details p i {
    font-size: 14px;
    margin-right: 2px;
    color: #000;
  }
  
  .business-item img {

  }

  
  .business-item h2 {
    margin-top: 10px;
    font-size: 1.2em;
    line-height: 0.9em;
  }
  
  .business-item p {
    margin-top: 5px;
    color: #555;
    border-bottom: 1px solid #fafafa;
    margin-bottom: 5px;
    padding-bottom: 5px;
  }

/* Media queries */

@media screen and (max-width: 1210px){
  .business-item h2 {
    font-size: 1.2em;
  }
  .business-item p {
    margin-bottom: 3px;
    padding-bottom: 3px;
    line-height: 1em;
  }
}

@media screen and (max-width: 1150px){

}

@media screen and (max-width: 980px){

}


@media screen and (max-width: 880px){
  .business-item-inner-image,
  .business-item-inner-details {
    width: 100%;
    flex: 100%;
  }
  .business-item-inner-image {
    min-height: 105px;
    border-radius: 5px 5px 0 0;
  }
  .business-item-inner-details {
    border-radius: 0 0 5px 5px;
  }
  .business-item p {
    font-size: 15px;
  }
}

@media screen and (max-width: 750px){
  .business-list-items {
    width: 100%;
    flex: 100%;
  }
  .Business-Directory .business-list-sidebar {
    display: none;
  }
  
} 

@media screen and (max-width: 650px){

}

@media screen and (max-width: 570px){
  .Business-Directory .directory-business-search-container input,
  .Business-Directory .directory-business-search-container button {
    font-size: 16px;
  }
  .Business-Directory .directory-business-search-container input {
    flex: 80% 0;
    width: 80%;
  }
  .Business-Directory .directory-business-search-container button {
    flex: 20% 0;
    width: 20%;
  }
  .business-item-inner-details {
    padding-left: 20px;
    padding-right: 20px;
  }
  .business-item p {
    font-size: 14px;
  }
  
}

@media screen and (max-width: 500px){

}

/* Loading */

.business-item {
  animation: fadeInAnimation 0.5s;
}

  @keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
  }