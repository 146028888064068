.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.popup-container h2 i {
  margin-right: 5px;
}

.popup {
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  width: 500px;
  height: 90%;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  align-content: center;
}

@media screen and (max-height: 800px){
  .popup {
    overflow-y: scroll;
  }
}

/* Target the scrollbar in WebKit browsers (Chrome, Safari) */
.popup::-webkit-scrollbar {
  width: 12px;
}

/* Handle */
.popup::-webkit-scrollbar-thumb {
  background-color: #6a6a6a; /* Color of the thumb */
  border-radius: 6px; /* Rounded corners */
}

/* Track */
.popup::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the track */
}

/* Handle on hover */
.popup::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  display: inline-flex;
  cursor: pointer;
}

.close-button:hover {
  opacity: 0.5;
}

.close-button:before {
  font-size: 30px;
}

.popup h2, .popup h3, .popup p {
  display: flex;
  flex: 100%;
  justify-content: center;
  color: #000;
  font-family: "Outfit";
}

.popup button {
  width: 100%;
  background: #a2c860;
  border: 3px solid rgba(0,0,0,0.1);
  font-size: 20px;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  font-family: "Outfit";
}

.popup button:hover {
  background: #000;
  color: #FFF;
}

.business-display-logo {
  display: flex;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.form-group {
  margin-bottom: 5px;
  text-align: left;
}

.form-group.hidden {
  display: none;
}

.form-group .field-container {
  padding: 5px;
  box-sizing: border-box;
}

.form-group.rating-column {
  display: inline-flex;
  flex: 100% 0 0;
  width: 100%;
  flex-wrap: wrap;
}

.rating-column .field-container { display: flex; } 

.form-group.rating-column label,
.form-group.rating-colum .star-rating label {
  display: inline-flex;
}

.form-group.rating-column .rating-details {
  display: flex;
  flex: 25%;
  width: 25%;
  flex-wrap: wrap;
}

.form-group.rating-column label { 
  display: flex;
  flex: 100% 0 0;
  width: 100%;
  margin-right: 10px;
}

.form-group.rating-column span {
  color: #555;
  font-size: 12px;
}

.form-group.two-columns {
  display: flex;
  flex: 100% 0 0;
  flex-wrap: wrap;
}

.form-group.two-columns .field-container {
  flex: 50% 0 0;
}

.form-group.two-columns .field-container .optional-tag {
  font-size: 12px;
  color: #555;
}

.form-group .error-message {
  background: #df5353;
  display: flex;
  color: #FFF;
  border: 2px solid #c84d4d;
  padding: 5px;
  box-sizing: border-box;
  font-size: 15px;
  text-align: center;
  justify-content: center;
  margin-top: 5px;
  border-radius: 11px;
}



.popup label {
  display: block;
  font-size: 16px;
}

.popup input,
.popup textarea,
.popup textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-sizing: border-box;
}

.popup input, 
.popup textarea {
  background: #EEE;
}

.popup textarea {
  height: 130px;
}

button {
  background-color: #4caf50;
  color: #fff;
  cursor: pointer;
}

@media screen and (max-width: 400px){
  .popup label {
    font-size: 14px;
  }
  .form-group.two-columns .field-container .optional-tag {
    font-size: 11px;
  }
  .popup button {
    font-size: 14px;
  }
  .popup {
    width: 95%;
  }
  .form-group .error-message {
    font-size: 12px;
  }
}

@media screen and (max-width: 385px){
  .popup label {
    font-size: 12px;
  }
}

@media screen and (max-width: 350px){
  .popup {
    padding: 10px;
  }
}