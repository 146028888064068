.Highlight-seventy {
    display: flex;
    flex: 70% 0 0;
    flex-wrap: wrap;
    text-align: left;
}

.Highlight-thirty {
    display: flex;
    flex: 30% 0 0;
    flex-wrap: wrap;
    text-align: left;
}

.Page-full-width ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.Highlight {
  display: flex;
  flex: 100% 0;
  justify-content: center;
  background: #eee;
}

.Highlight-content {
    display: flex;
    width: 1200px;
    margin-top: 0;
    z-index: 2;
    flex-wrap: wrap;
    border-radius: 20px;
  }

  .Highlight-content-inner {
    display: flex;
    flex-wrap: wrap;
    padding: 60px 50px 85px;
  }

  .Highlight-content-inner h2 {
    font-size: 33px;
    margin-top: 0;
  }

    .Highlight-content-inner p,
    .Highlight-content-inner li {
        font-size: 18px;
    }

  .Highlight-content-inner p,
  .Highlight-content-inner h2,
  .Highlight-content-inner li {
    font-family: "Outfit";
  }